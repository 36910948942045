import { ApplicantMatCardComponent } from '../components/applicant/langing-page/mat-card/mat-card.component';
import { ScatterQuadrentChartComponent } from "./../components/HR/scatter-quadrent-chart/scatter-quadrent-chart.component";
import { RoleSwitcherComponent } from "./common/role-switcher/role-switcher.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { ClientSelectionComponent } from "./common/client-selection/client-selection.component";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";
import { LayoutModule } from "@angular/cdk/layout";
//import { SocialLoginModule } from "@abacritt/angularx-social-login";
import { NavigationModule } from "../navigation/navigation.module";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core'; // For native date adapter
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTreeModule } from '@angular/material/tree';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';
import { CarouselModule } from "ngx-owl-carousel-o";
import { CommonModule } from "@angular/common";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { DataTablesModule } from "angular-datatables";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import {
  NgbPaginationModule,
  NgbAlertModule,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { AddGroupComponent } from "./common/add-group/add-group.component";
import { GroupSetupComponent } from "./common/group-setup/group-setup.component";
import { FeedbackComponent } from "./common/feedback/feedback.component";
import { TakeTestLtiFormComponent } from "./common/take-test-lti-form/take-test-lti-form.component";
import { ImmediateFeedbackComponent } from "./common/immediate-feedback/immediate-feedback.component";
import { CommonDialogAlertComponent } from "./common/common-dialog-alert/common-dialog-alert.component";
import { PageNotFoundComponent } from "./common/page-not-found/page-not-found.component";
import { NavComponent } from "./common/nav/nav.component";
import { LogoComponent } from "./common/logo/logo.component";
import { RoleSelectionComponent } from "../navigation/role-selection/role-selection.component";
import { EmployeeHeaderComponent } from "./common/employee-header/employee-header.component";
import { SearchComponent } from "./common/search/search.component";
import { SwitcherComponent } from "./common/switcher/switcher.component";
import { ImgComponent } from "./common/img/img.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCardComponent } from "../components/employee/langing-page/mat-card/mat-card.component";
import { CareerCardComponent } from "./common/career-card/career-card.component";
import { ResultCardComponent } from "./common/result-card/result-card.component";
import { CarrierRecommendationCardComponent } from "./common/carrier-recommendation-card/carrier-recommendation-card.component";
import { PeopleCardComponent } from "./common/people-card/people-card.component";
import { ApImmediateFeedbackComponent } from "./common/ap-immediate-feedback/ap-immediate-feedback.component";
import { ImageFramingCropingComponent } from "./common/image-framing-croping/image-framing-croping.component";
import { EmployeeProfileComponent } from "./common/employee-profile/employee-profile.component";
import { AssessmentCardComponent } from "./common/assessment-card/assessment-card.component";
import { EmployeeCareerCardComponent } from "./common/employee-career-card/employee-career-card.component";
import { RecommendedCareerCardComponent } from "./common/recommended-career-card/recommended-career-card.component";
import { HrRecommendCareerComponent } from "./common/hr-recommend-career/hr-recommend-career.component";
import { ToDoProgressBarComponent } from "./common/to-do-progress-bar/to-do-progress-bar.component";
import { PeopleInfoCardComponent } from "./common/people-info-card/people-info-card.component";
import { HrCareerSummaryInfoTileComponent } from "./common/hr-career-summary-info-tile/hr-career-summary-info-tile.component";
import { MessageDialogComponent } from "./common/message-dialog/message-dialog.component";
import { ResponsibilitiesMoreComponent } from "./common/responsibilities-more/responsibilities-more.component";
import { EmployeeLearningProgramCardComponent } from "./common/employee-learning-program-card/employee-learning-program-card.component";
import { FileUploadNotificationSideBarComponent } from "./common/file-upload-notification-side-bar/file-upload-notification-side-bar.component";
import { AlertMessageComponent } from "./common/alert-message/alert-message.component";
import { EmsiSkillComponentComponent } from "./common/emsi-skill-component/emsi-skill-component.component";
import { EmsiSkillOverviewComponent } from "./common/emsi-skill-overview/emsi-skill-overview.component";
import { BadgeComponent } from "./common/badge/badge.component";
import { DefaultDashboardComponent } from "./common/default-dashboard/default-dashboard.component";
import { LandingPageComponent } from "./common/home/landing-page/landing-page.component";
import { TakeTestComponent } from "../components/Auth/TakeTest/take-test/take-test.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CustomFileNameComponent } from "./common/custom-file-name/custom-file-name.component";
import { EmployeeAssessmentReminderComponent } from "./common/employee-assessment-reminder/employee-assessment-reminder.component";
import { ImagePreviewComponent } from "../components/admin/Manage-Data/Manage-CZ-Media/image-preview/image-preview.component";
import { MediaFileUploaderComponent } from "../components/admin/Manage-Data/Manage-CZ-Media/media-file-uploader/media-file-uploader.component";
import { BulkImageUploaderComponent } from "../components/admin/Manage-Data/Manage-CZ-Media/bulk-image-uploader/bulk-image-uploader.component";
import { PopupModalComponent } from "./common/popup-modal/popup-modal.component";
import { FileUploaderComponent } from "./common/file-uploader/file-uploader.component";
import { WIPResultUploadErrorSummaryDialogComponentComponent } from "./common/WIP-ResultUpload-ErrorSummary-DialogComponent/WIP-ResultUpload-ErrorSummary-DialogComponent.component";
import { HrSkillGridComponent } from "./common/hr-skill-grid/hr-skill-grid.component";
import { SummeryNumberCardComponent } from "./common/summery-number-card/summery-number-card.component";
import { CampusSummaryComponent } from "./common/campus-summary/campus-summary.component";
import { StudentSummeryComponent } from "./common/student-summery/student-summery.component";
import { GaugeChartComponent } from "./common/gauge-chart/gauge-chart.component";

import { NgApexchartsModule } from "ng-apexcharts";
import { PermissionCheckDirective } from "../_directives/permission-check.directive";
import { AccessDeniedComponent } from "./common/access-denied/access-denied.component";
import { UniversityPeopleCardComponent } from './common/university-people-card/university-people-card.component';
import { UniversityStudentSummeryComponent } from './common/university-student-summery/university-student-summery.component';
import { UniversityEmsiSkillOverviewComponent } from './common/university-program-overview/university-program-overview.component';
import { MatRadioModule } from '@angular/material/radio';
import { CareerDetailsPopupComponent } from './common/career-details-popup/career-details-popup.component';

@NgModule({

  schemas: [
    NO_ERRORS_SCHEMA
  ],
  declarations: [
    GroupSetupComponent,
    AddGroupComponent,
    FeedbackComponent,
    TakeTestLtiFormComponent,
    ImmediateFeedbackComponent,
    ApImmediateFeedbackComponent,
    ClientSelectionComponent,
    CommonDialogAlertComponent,
    PageNotFoundComponent,
    NavComponent,
    LogoComponent,
    RoleSelectionComponent,
    ImageFramingCropingComponent,
    EmployeeHeaderComponent,
    SearchComponent,
    SwitcherComponent,
    ImgComponent,
    MatCardComponent,
    CareerCardComponent,
    PeopleCardComponent,
    ResultCardComponent,
    CarrierRecommendationCardComponent,
    RoleSwitcherComponent,
    EmployeeProfileComponent,
    AssessmentCardComponent,
    EmployeeCareerCardComponent,
    RecommendedCareerCardComponent,
    ScatterQuadrentChartComponent,
    HrRecommendCareerComponent,
    ToDoProgressBarComponent,
    PeopleInfoCardComponent,
    HrCareerSummaryInfoTileComponent,
    MessageDialogComponent,
    ResponsibilitiesMoreComponent,
    EmployeeLearningProgramCardComponent,
    FileUploadNotificationSideBarComponent,
    AlertMessageComponent,
    EmsiSkillComponentComponent,
    EmsiSkillOverviewComponent,
    BadgeComponent,
    DefaultDashboardComponent,
    LandingPageComponent,
    TakeTestComponent,
    CustomFileNameComponent,
    EmployeeAssessmentReminderComponent,
    ImagePreviewComponent,
    MediaFileUploaderComponent,
    BulkImageUploaderComponent,
    PopupModalComponent,
    FileUploaderComponent,
    WIPResultUploadErrorSummaryDialogComponentComponent,
    HrSkillGridComponent,
    SummeryNumberCardComponent,
    CampusSummaryComponent,
    StudentSummeryComponent,
    UniversityStudentSummeryComponent,
    GaugeChartComponent,
    PermissionCheckDirective,
    AccessDeniedComponent,
    ApplicantMatCardComponent,
    UniversityPeopleCardComponent,
    UniversityEmsiSkillOverviewComponent,
    CareerDetailsPopupComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
    NgSelectModule,
    LayoutModule,
    // SocialLoginModule,
    NavigationModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatCardModule,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CarouselModule,
    MatRadioModule,
    BsDropdownModule.forRoot(),
    InfiniteScrollModule,
    MatSlideToggleModule,
    MatTabsModule,
    CommonModule,
    DataTablesModule,
    HttpClientModule,
    MatSortModule,
    NgMultiSelectDropDownModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatTreeModule,
    MatProgressBarModule,
    MatStepperModule,
    MatTooltipModule,
    MatBadgeModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgbModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    NgApexchartsModule,
    MatRadioModule
  ],
  exports: [
    HrSkillGridComponent,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    LayoutModule,
    // SocialLoginModule,
    NavigationModule,
    MatCardComponent,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatCardModule,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CarouselModule,
    MatAutocompleteModule,
    MatRadioModule,
    InfiniteScrollModule,
    MatSlideToggleModule,
    CommonModule,
    DataTablesModule,
    HttpClientModule,
    NgMultiSelectDropDownModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatTreeModule,
    MatSortModule,
    MatProgressBarModule,
    MatStepperModule,
    MatTooltipModule,
    MatBadgeModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgbModule,
    MatProgressSpinnerModule,
    GroupSetupComponent,
    AddGroupComponent,
    ImageFramingCropingComponent,
    FeedbackComponent,
    TakeTestLtiFormComponent,
    ImmediateFeedbackComponent,
    ApImmediateFeedbackComponent,
    NavComponent,
    LogoComponent,
    ImgComponent,
    CareerCardComponent,
    PeopleCardComponent,
    ResultCardComponent,
    CarrierRecommendationCardComponent,
    RoleSwitcherComponent,
    EmployeeProfileComponent,
    AssessmentCardComponent,
    EmployeeCareerCardComponent,
    RecommendedCareerCardComponent,
    ScatterQuadrentChartComponent,
    HrRecommendCareerComponent,
    ToDoProgressBarComponent,
    PeopleInfoCardComponent,
    HrCareerSummaryInfoTileComponent,
    MessageDialogComponent,
    ResponsibilitiesMoreComponent,
    EmployeeLearningProgramCardComponent,
    FileUploadNotificationSideBarComponent,
    AlertMessageComponent,
    EmsiSkillComponentComponent,
    EmsiSkillOverviewComponent,
    BadgeComponent,
    DefaultDashboardComponent,
    LandingPageComponent,
    TakeTestComponent,
    CustomFileNameComponent,
    EmployeeAssessmentReminderComponent,
    ImagePreviewComponent,
    MediaFileUploaderComponent,
    BulkImageUploaderComponent,
    PopupModalComponent,
    FileUploaderComponent,
    SummeryNumberCardComponent,
    CampusSummaryComponent,
    StudentSummeryComponent,
    UniversityStudentSummeryComponent,
    GaugeChartComponent,
    PermissionCheckDirective,
    ApplicantMatCardComponent,
    UniversityPeopleCardComponent,
    UniversityEmsiSkillOverviewComponent,
    CareerDetailsPopupComponent,
    MatRadioModule
  ],
  providers: [],
})
export class SharedModule {
  constructor() {
    console.log("shared module loaded");
  }
}
