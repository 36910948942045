<!--side bar -->
<div class="side-bar {{ className }}" *ngIf="isSideBar">
  <h4 class="text-center">Uploaded Files</h4>
  <hr>
  <!-- <div class="alert alert-info mt-3" role="alert" *ngIf="progress===0">
    <span class="text-center">There are no uploads here!</span>
  </div> -->
  <div class="row mt-2 mb-1" style="height: 400px; overflow: auto">
    <!-- <div class="col-md-12 overflow-auto" *ngIf="processes!==null && processes!==undefined && processes.length>0"> -->
    <div class="col-md-12 overflow-auto">
      <!-- current upload files -->
      <!-- *ngFor="let process of processes" -->
      <div class="row mt-2 mb-1">
        <div *ngFor="let process of processes" class="col-md-12 mb-3">
          <div style="background: #8984f321; border-radius: 5px; box-shadow: none" class="
              card-body
              box-shadow
              align-items-center
              px-3
              py-2
              position-relative
            ">
            <!-- <a (click)="onCloseProcess(process)" class="cossButton"><i class="fas fa-times"></i></a> -->
            <span>
              <i style="font-size: 41px; color: #7069f8" class="fas fa-file-alt"></i>
            </span>
            <div class="d-inline-block w-75 ml-3">
              <div class="d-flex justify-content-between">
                <a title="{{process.fileName}}" class="fileName"
                  style="font-size: 14px; cursor: pointer"><b>{{process.fileName}}</b>
                  <b class="badge badge-info badge-pill" style="margin-left: 5px;
                letter-spacing: 2px;"
                    *ngIf="processType===29 && rowProcessCount!=totalRowCount">{{rowProcessCount}}/{{totalRowCount}}</b>
                </a>
                <span>{{process.statusName}} </span>
              </div>
              <div class="progress">
                <div class="progress-bar w-{{process.processPercent}}" role="progressbar" aria-valuenow="50"
                  aria-valuemin="0" aria-valuemax="100" [style.width.%]="process.processPercent"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span style="margin-top: 50px !important; margin: 0 auto; font-size: 16px; font-weight: bold;"
      *ngIf="processes===null || processes===undefined || processes.length===0">No data to
      display.</span>
  </div>
</div>