import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { RoleEnum } from '../../../_enums/RoleEnum';
import { AppAuthService } from 'src/app/pages/Shared/_services/auth.service';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertifyService } from 'src/app/pages/Shared/_services/alertify.service';
import { ClientService } from 'src/app/pages/Shared/_services/client.service';
import { UiSyncService } from 'src/app/pages/Shared/_services/ui-sync.service';
import { Client } from 'src/app/_models/client';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  loading = false;
  
  constructor(private router: Router) { 
    this.router.events.subscribe(event=>{
      switch (true) {
            case event instanceof NavigationStart: {
              this.loading = true;
              break;
            }
    
            case event instanceof NavigationEnd:
            case event instanceof NavigationCancel:
            case event instanceof NavigationError: {
              this.loading = false;
              break;
            }
            default: {
              break;
            }
          }
    })
  }

  ngOnInit() { 
    console.log('Home Component');
  }
}
