import { Input, ViewEncapsulation } from '@angular/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MessageDialogComponent implements OnInit {
  @Input() message: string;
  @Input() popupButtonName: string;
  @Input() isInformationPopup: boolean;
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() isSuccess = false;

  constructor() { }

  ngOnInit() {
  }

  onClose() {
    this.closeDialog.emit(true);
  }
}
