<!-- Header section start -->
<header class="header-section">
  <nav class="navbar navbar-expand-md" style="padding: 4px 16px 5px;">
    <div class="container">
      <!-- <a class="navbar-brand"><img src="assets/logo/logo.png" alt="Catalyzr Logo" class="img-responsive"></a> -->
      <!-- logo here -->
      <app-logo></app-logo>

      <div *ngIf="loggedId()" class="collapse navbar-collapse serach-bar-area">
        <!-- employee landing page search here -->
        <form class="header-form mr-auto mt-2 mt-md-0" *ngIf="
            isShowSearch === true && isApplicableReportingBackIcon === false
          " #searchForm="ngForm" (ngSubmit)="onSearchClick(searchForm.value)">
          <input type="text" placeholder="Search" list="searchKey" [(ngModel)]="searchValue" name="searchValue"
            (input)="searchKeyChange($event)" />
          <a (click)="onSearchClick(searchForm.value)" class="header-search-icon"><img src="assets/employee/Search.png"
              alt="Search" /></a>
          <datalist id="searchKey">
            <option *ngFor="let item of jobLists">{{ item }}</option>
          </datalist>
        </form>
      </div>

      <div *ngIf="loggedId()" class="user-info-header my-2 my-md-0">
        <form method="POST" class="form-inline user-info-form">

          <!-- process icon -->

          <div matTooltip="Processes" class="processesIcon toggle mr-3" *ngIf="isApplicableProcessIcon">
            <a (click)="toggleProcessModal()" class="settings"><i style="color: #7069f8"
                class="fas fa-sync {{ isProcessLoading }}"></i></a>
            <app-file-upload-notification-side-bar *ngIf="isVisbleProcessIcon" [totalRowCount]="TotalRowCount"
              [rowProcessCount]="RowProcessCount" [processType]="ProcessType"></app-file-upload-notification-side-bar>
          </div>


          <!-- reporting icon here -->
          <a *appPermissionCheck="40" (click)="onClickReportingTool()">
            <svg class="reporting-icon" matTooltip="Reporting Tool" version="1.1" id="Capa_1"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 60 60" style="enable-background: new 0 0 60 60" xml:space="preserve"
              style="transform: scale(.5);">
              <g>
                <g>
                  <path d="M56.5,49L56.5,49V1c0-0.6-0.4-1-1-1h-45c-0.6,0-1,0.4-1,1v14h2V2h43v46h-9c-0.6,0-1,0.4-1,1v9h-33V43h-2v16
                  c0,0.6,0.4,1,1,1h35c0.3,0,0.5-0.1,0.7-0.3l10-10c0.1-0.1,0.1-0.2,0.2-0.3v-0.1C56.5,49.2,56.5,49.1,56.5,49z M46.5,50h6.6
                  l-3.3,3.3l-3.3,3.3L46.5,50L46.5,50z" />
                  <path d="M16.5,38h6h4v-2h-3V17c0-0.6-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1v6h-5c-0.6,0-1,0.4-1,1v4h-5c-0.6,0-1,0.4-1,1v8
                  c0,0.6,0.4,1,1,1h6H16.5z M17.5,18h4v18h-4V24V18z M11.5,25h4v11h-4v-7V25z M5.5,30h4v6h-4V30z" />
                  <path
                    d="M50.5,24V7c0-0.6-0.4-1-1-1h-21c-0.6,0-1,0.4-1,1v17c0,0.6,0.4,1,1,1h21C50.1,25,50.5,24.6,50.5,24z M48.5,12h-12V8h12V12
                  z M34.5,8v4h-5c0-1.6,0-4,0-4H34.5z M29.5,14h5v9h-5C29.5,23,29.5,18.3,29.5,14z M36.5,23v-9h12v9H36.5z" />
                  <rect x="28.5" y="28" width="21" height="2" />
                  <rect x="28.5" y="33" width="21" height="2" />
                  <rect x="28.5" y="38" width="21" height="2" />
                  <rect x="14.5" y="6" width="6" height="2" />
                  <rect x="14.5" y="11" width="9" height="2" />
                  <rect x="14.5" y="43" width="7" height="2" />
                  <rect x="24.5" y="43" width="7" height="2" />
                  <rect x="34.5" y="43" width="7" height="2" />
                  <rect x="14.5" y="48" width="7" height="2" />
                  <rect x="24.5" y="48" width="7" height="2" />
                  <rect x="34.5" y="48" width="7" height="2" />
                  <rect x="14.5" y="53" width="7" height="2" />
                  <rect x="24.5" y="53" width="7" height="2" />
                  <rect x="34.5" y="53" width="7" height="2" />
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </a>

          <!-- role switcher here -->
          <div class="role-switcher">
            <app-role-switcher *ngIf="isShowDropdown === true && isApplicableReportingBackIcon === false"
              [userModules]="allUserModules" [selectedRoleId]="selectedRoleId" [selectedModuleId]="selectedModuleId">
            </app-role-switcher>
          </div>

          <!-- reporting back button -->
          <a *ngIf="isApplicableReportingBackIcon === true" style="font-size: 30px; padding-left: 25px; margin-top: 3px"
            matTooltip="Back to Home Page" (click)="onClickReportingBackButton()">
            <i style="color: #7069f8" class="las la-arrow-alt-circle-left"></i>
          </a>

          <!-- client switcher here -->
          <svg *ngIf="
              isApplicableClientSwitcher !== null &&
              isApplicableClientSwitcher !== undefined &&
              isApplicableClientSwitcher === true
            " (click)="onClickSwitcher()" class="bi bi-compass clientSwitcher" title="Client Switcher" style="
              color: black;
              z-index: 2;
              margin-left: 20px;
              font-size: 27px;
              cursor: pointer;
            " width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M8 15.016a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13zm0 1a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15z" />
            <path
              d="M6 1a1 1 0 0 1 1-1h2a1 1 0 0 1 0 2H7a1 1 0 0 1-1-1zm.94 6.44l4.95-2.83-2.83 4.95-4.95 2.83 2.83-4.95z" />
          </svg>

          <!-- employee profile here -->
          <div class="employee-info">
            <img (click)="openEmployeeHeader()" *ngIf="
                employeeImageUrl === null ||
                employeeImageUrl === undefined ||
                employeeImageUrl === ''
              " class="employee-image" src="assets/employee/employee_icon.png" alt="Profile" />
            <img (click)="openEmployeeHeader()" *ngIf="
                loading &&
                employeeImageUrl !== null &&
                employeeImageUrl !== undefined &&
                employeeImageUrl !== ''
              " class="avatar" src="assets/images/image-loading.gif" style="height: 40px; width: 40px" alt="loading" />
            <img [hidden]="loading" *ngIf="
                employeeImageUrl !== null &&
                employeeImageUrl !== undefined &&
                employeeImageUrl !== ''
              " class="employee-image" (load)="onLoad()" (click)="openEmployeeHeader()" src="{{ employeeImageUrl }}"
              alt="Profile" />
            <a (click)="openEmployeeHeader()" id="employeeName" class="employee-name">{{ loginUser.firstName }} {{
              loginUser.lastName }}</a>
            <!-- Header section end -->
            <app-employee-profile *ngIf="loggedId() && isShowEmployeeProfile"
              [isShowEmployeeProfile]="isShowEmployeeProfile" (onLoggedOut)="onLoggedOut($event)"
              (onClosePopup)="onCloseEmployeeProfile($event)">
            </app-employee-profile>
          </div>
        </form>
      </div>
    </div>
  </nav>
</header>