import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AppAuthService } from "../pages/Shared/_services/auth.service";
import { SectionComponent } from "../_models/user-permission/component";
import { Subscription } from "rxjs";

@Directive({
  selector: "[appPermissionCheck]",
})
export class PermissionCheckDirective {
  @Input("appPermissionCheck") componentId: number;
  components: SectionComponent[] = [];
  private subscriptions = new Subscription();
  constructor(
    private viewContainerRef: ViewContainerRef,
    private authService: AppAuthService,
    private templateRef: TemplateRef<any>
  ) {
    this.subscriptions.add(this.authService.userComponent$.subscribe((components) => {
      this.components = components;
    }));
  }

  ngOnInit(): void {
    if (
      this.components.some(
        (c) =>
          c.id == this.componentId || c.parentComponentId == this.componentId
      )
    ) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    this.subscriptions.unsubscribe();

  }
}
