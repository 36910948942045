<!-- <div class="container-fluid">
  <div class="card">
    <div class="card-header bg-colour text-white justify-content-between">
      <div class="row">
        <div class="col-md-12">
          <span style="font-weight: bold; font-size: 20px;">Image Upload</span>
          <span class="float-right" (click)="close()"
            style="font-size: 25px; font-weight: bold; cursor: pointer;">&times;</span>
        </div>
      </div>
    </div>
    <div class="card-body mat-dialog-content">
      <div class="row text-center mt-3 mb-2">
        <div class="col-md-12" style="overflow: hidden;">
          <img (click)="handleFileInput($event.target.files)" #image crossorigin class="card-img-top-user" src="{{ imageUrl }}"
            alt="Employee Profile Image" />
        </div>
      </div>
      <div class="row  mb-2 text-right">
        <div class="col-md-12">
          <button (click)="onApply()" class="btn btn-success">Apply</button>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div id="myModalThree"  *ngIf="showPopup" class="modal modal-style-3 imageUploadModal">
  <div class="modal-content-wrapper">
    <div class="modal-content">
      <h3>Image Upload</h3>
      <div class="image-load-content-wrapper">
        <img (click)="handleFileInput($event.target.files)" #image crossorigin class="card-img-top-user" src="{{ imageUrl }}" alt="Employee Profile Image" />
        <button class="button btn-color" (click)="onApply()">Apply</button>
      </div>
    </div>
      <div class="modal-close">
        <img class="closeBtnFour" (click)="close()" src="assets/employee/cancel.png" alt="">
      </div>
  </div>
</div>

