import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppAuthService } from '../pages/Shared/_services/auth.service';
import { UiSyncService } from '../pages/Shared/_services/ui-sync.service';
import { ModuleEnum } from '../_enums/module-enum';

@Injectable({
  providedIn: 'root'
})
export class CampusRecruiterGuard implements CanActivate {



  constructor( private authService: AppAuthService,
    private router: Router){
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):   boolean {     
      let userModules = this.authService.currentUser.modules;
      if (userModules && userModules.some(c => c.id == ModuleEnum.CampusRecruiter)) {
        return true;
      }
    this.router.navigate(['/access-denied']);
    return false;
  }
  
}
