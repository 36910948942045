import { ChartDataDto } from 'src/app/_models/dto/chart-data-dto';
import { EmployeeMyListCareer } from './../../../_models/dto/employee-mylist-career';
import { HrFacetedFilter } from './../../../_models/dto/hr-faceted-filter';
import { UniversityFacetedFilter } from '../../../_models/dto/university-faceted-filter';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Group } from 'src/app/_models/group';
import { UserRoles } from 'src/app/_models/userRoles';
import { Client } from 'src/app/_models/client';
import { JobRecommendation } from 'src/app/_models/test-result-view-models/job-recommendation';
import { CareerGroup } from 'src/app/_models/career-group';
import { RouteParams } from 'src/app/_models/dto/route-params';
import { Process } from 'src/app/_models/dto/process';
import { ResultRequestDto } from 'src/app/_models/dto/result-request-dto';
import { TabEnum } from 'src/app/_enums/tab-enum';
import { ResultProcessStatus } from 'src/app/_models/resultProcessStaus';

@Injectable({
  providedIn: 'root'
})
export class UiSyncService {

  constructor() {

  }

  onSelectDeliveryName: Subject<string> = new Subject<string>();
  onSelectTestTakerName: Subject<string> = new Subject<string>();
  onResultIdSelected: Subject<number> = new Subject<number>();
  onWIPresultIdSelected: Subject<number> = new Subject<number>();
  isManuallyUploaded: Subject<ResultRequestDto> = new Subject<ResultRequestDto>();
  //assessmentTypeId : Subject<number> = new Subject<number>();
  onDeliveryUriSelected: Subject<string> = new Subject<string>();
  onGroupSelected: Subject<Group> = new Subject<Group>();
  onClientSelected: Subject<number> = new Subject<number>();
  onClientSelectedBehaviour = new BehaviorSubject<number>(0);
  selectedClientId: number;
  onSelectedClientName: Subject<string> = new Subject<string>();
  selectedClientName: string = '';
  toggleDeliveriesSelection: Subject<boolean> = new Subject<boolean>();
  onSignUpSelect: Subject<boolean> = new Subject<boolean>();
  onLoginSelect: Subject<boolean> = new Subject<boolean>();
  onAboutUsSelect: Subject<boolean> = new Subject<boolean>();
  catalyzrPersonId: Subject<string> = new Subject<string>();
  accessLogToken: Subject<string> = new Subject<string>();
  onClientRemoveFromClientDashboard: Subject<boolean> = new Subject<boolean>();
  userAllRoles: Subject<UserRoles[]> = new Subject<UserRoles[]>();
  allClients: Subject<Client[]> = new Subject<Client[]>();
  onSelectedRoleId: Subject<{ roleId: number, isUrl?: boolean }> = new Subject<{ roleId: number, isUrl?: boolean }>();
  selectedRoleId: number;
  isDisableClientDropdown: Subject<boolean> = new Subject<boolean>();
  employeeImageUrl: Subject<string> = new Subject<string>();
  clientLogoUrl: Subject<string> = new Subject<string>();
  careerMyList: Subject<EmployeeMyListCareer[]> = new Subject<EmployeeMyListCareer[]>();
  onToggleEmployeeProfile: Subject<boolean> = new Subject<boolean>();
  onThumbsDownCareer: Subject<{ clientJobCode: string, isThumbsDown: boolean }> = new Subject<{ clientJobCode: string, isThumbsDown: boolean }>();
  onThumbsUpCareer: Subject<{ clientJobCode: string, isThumbsUp: boolean }> = new Subject<{ clientJobCode: string, isThumbsUp: boolean }>();
  onSelectedCZJobFamilyId: Subject<number> = new Subject<number>();
  onHrSelectedGroupId: Subject<number> = new Subject<number>();
  onHrPeopleSelectedTab: Subject<boolean> = new Subject<boolean>();
  onUniversitySelectedGroupId: Subject<number> = new Subject<number>();
  onUniversityPeopleSelectedTab: Subject<boolean> = new Subject<boolean>();
  onCrTabSelect: Subject<number> = new Subject<number>();
  onUniversityTabSelect: Subject<number> = new Subject<number>();
  clickOnCareerChartBubble: Subject<ChartDataDto> = new Subject<ChartDataDto>();
  hrCareerGroups: Subject<CareerGroup[]> = new Subject<CareerGroup[]>();
  universityCareerGroups: Subject<CareerGroup[]> = new Subject<CareerGroup[]>();
  selectedHrFacetedFilter: Subject<HrFacetedFilter> = new Subject<HrFacetedFilter>();
  onDetailsHRPeopleProposedCareer: Subject<string> = new Subject<string>();
  selectedUniversityFacetedFilter: Subject<HrFacetedFilter> = new Subject<HrFacetedFilter>();
  onDetailsUniversityPeopleProposedCareer: Subject<string> = new Subject<string>();
  onClickPeopleChartBubble: Subject<any> = new Subject<any>();
  onSelectedRouteParams: Subject<RouteParams> = new Subject<RouteParams>();
  onReloadHrLandingPage: Subject<boolean> = new Subject<boolean>();
  onReloadUniversityLandingPage: Subject<boolean> = new Subject<boolean>();
  onClientSelectionForAQBReporting: Subject<number> = new Subject<number>();
  onDisplayAQBReporting: Subject<boolean> = new Subject<boolean>();
  setProcess: Subject<Process> = new Subject<Process>();
  isLoadingProcess: Subject<boolean> = new Subject<boolean>();
  isLoadingProcessForClient: Subject<boolean> = new Subject<boolean>();
  isVisbleProcessIcon: Subject<boolean> = new Subject<boolean>();
  isEmployeeResultProcessed: Subject<boolean> = new Subject<boolean>();
  onLoggedOut: Subject<boolean> = new Subject<boolean>();

  private onSelectedModuleSource: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  selectedModuleId: number;
  onSelectedModule$ = this.onSelectedModuleSource.asObservable();
  // sessionId: number;

  public isShowAppliedRequisition: boolean = false;
  public selectedCrTab: TabEnum = TabEnum.DashboardTab;

  assessmentSessionStartFor: Subject<number> = new Subject<number>();
  isDontShowToDoWarning: boolean = false;
  isDisableCongratulationMessage: boolean = false;
  // assessmentTimes: { assessmentId: number, remainingTime: number }[] = [];
  setSelectedModule(moduleId: number) {
    this.selectedModuleId = moduleId;
    this.onSelectedModuleSource.next(moduleId);
    localStorage.setItem("selectedModuleId", moduleId.toString());
  }
  setCrSelectedTab(tabId: TabEnum) {
    this.selectedCrTab = tabId;
  }
  runningAssessments: { assessmentId: number, sessionId: number, remainingTimeInSeconds: number, timeLimitInSec: number, userId: number }[] = [];
  resultProcessed: { assessmentId: number, assessmentTypeId: number, userId: number }[] = [];

  resultProcessing: BehaviorSubject<ResultProcessStatus[]> = new BehaviorSubject<ResultProcessStatus[]>([]);


  clearUiSyncService() {
    // Reset Subjects
    this.onSelectDeliveryName.next('');
    this.onSelectTestTakerName.next('');
    this.onResultIdSelected.next(null);
    this.onWIPresultIdSelected.next(null);
    this.isManuallyUploaded.next(null);
    this.onDeliveryUriSelected.next('');
    this.onGroupSelected.next(null);
    this.onClientSelected.next(null);
    this.onClientSelectedBehaviour.next(0);
    this.selectedClientId = null;
    this.onSelectedClientName.next('');
    this.selectedClientName = '';
    this.toggleDeliveriesSelection.next(false);
    this.onSignUpSelect.next(false);
    this.onLoginSelect.next(false);
    this.onAboutUsSelect.next(false);
    this.catalyzrPersonId.next('');
    this.accessLogToken.next('');
    this.onClientRemoveFromClientDashboard.next(false);
    this.userAllRoles.next([]);
    this.allClients.next([]);
    this.onSelectedRoleId.next({ roleId: null });
    this.selectedRoleId = null;
    this.isDisableClientDropdown.next(false);
    this.employeeImageUrl.next('');
    this.clientLogoUrl.next('');
    this.careerMyList.next([]);
    this.onToggleEmployeeProfile.next(false);
    this.onThumbsDownCareer.next({ clientJobCode: '', isThumbsDown: false });
    this.onThumbsUpCareer.next({ clientJobCode: '', isThumbsUp: false });
    this.onSelectedCZJobFamilyId.next(null);
    this.onHrSelectedGroupId.next(null);
    this.onHrPeopleSelectedTab.next(false);
    this.onUniversitySelectedGroupId.next(null);
    this.onUniversityPeopleSelectedTab.next(false);
    this.onCrTabSelect.next(null);
    this.onUniversityTabSelect.next(null);
    this.clickOnCareerChartBubble.next(null);
    this.hrCareerGroups.next([]);
    this.universityCareerGroups.next([]);
    this.selectedHrFacetedFilter.next(null);
    this.onDetailsHRPeopleProposedCareer.next('');
    this.selectedUniversityFacetedFilter.next(null);
    this.onDetailsUniversityPeopleProposedCareer.next('');
    this.onClickPeopleChartBubble.next(null);
    this.onSelectedRouteParams.next(null);
    this.onReloadHrLandingPage.next(false);
    this.onReloadUniversityLandingPage.next(false);
    this.onClientSelectionForAQBReporting.next(null);
    this.onDisplayAQBReporting.next(false);
    this.setProcess.next(null);
    this.isLoadingProcess.next(false);
    this.isLoadingProcessForClient.next(false);
    this.isVisbleProcessIcon.next(false);
    this.isEmployeeResultProcessed.next(false);
    this.onLoggedOut.next(false);

    this.onSelectedModuleSource.next(0);

    this.selectedModuleId = null;
    this.isShowAppliedRequisition = false;
    this.selectedCrTab = TabEnum.DashboardTab;
    this.runningAssessments = [];
    this.resultProcessed = [];
    this.resultProcessing.next([]);
  }

}
