import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertifyService } from 'src/app/pages/Shared/_services/alertify.service';
import { AppAuthService } from 'src/app/pages/Shared/_services/auth.service';
import { UiSyncService } from 'src/app/pages/Shared/_services/ui-sync.service';
import { RoleEnum } from 'src/app/_enums/RoleEnum';
import { Role } from 'src/app/_models/role';
import { UserRoles } from 'src/app/_models/userRoles';
import { Module } from 'src/app/_models/user-permission/module';
import { take } from 'rxjs-compat/operator/take';

@Component({
  selector: 'app-role-switcher',
  templateUrl: './role-switcher.component.html',
  styleUrls: ['./role-switcher.component.css']
})
export class RoleSwitcherComponent implements OnInit {
  @Input('userRoles') userRoles: UserRoles[] = [];
  @Input('userModules') userModules: Module[] = [];
  @Input('selectedRoleId') selectedRoleId = 0;
  @Input('selectedModuleId') selectModuleId = 0;
  selectedModuleId: number | undefined;
  isApplicableRoleSwitcher = true;
  modules: Module[];
  constructor(
    private uiSyncService: UiSyncService,
    private alertify: AlertifyService,
    private router: Router,
    private authService: AppAuthService,
  ) { }

  ngOnInit() {

    // if (this.userModules !== null && this.userModules !== undefined && this.userModules.length > 0) {

    //   this.hrRoleEnable();
    // }
    this.modules = this.userModules
    this.selectedModuleId = this.selectModuleId;
    this.checkAvailabilityForRoleSwitcher();
  }

  // on role chnages
  onModuleChanges(event: any) {
    this.authService.selectedRoleId = +event;
    this.uiSyncService.selectedRoleId = +event;

    this.uiSyncService.onSelectedRoleId.next({ roleId: +event, isUrl: false })

    this.uiSyncService.setSelectedModule(+event);
    this.selectedModuleId = this.uiSyncService.selectedModuleId;
    this.authService.onLogoClick()
  }
  // check availability for role switcher
  checkAvailabilityForRoleSwitcher() {

    if (this.userModules !== null && this.userModules !== undefined && this.userModules.length > 1) {
      this.isApplicableRoleSwitcher = true;
    } else {
      this.isApplicableRoleSwitcher = false;
    }
  }
  // role enable
  hrRoleEnable() {
    const isAdminRolePersist = this.userRoles.find(c => c.roleId === RoleEnum.Admin);
    const isEmployeeRolePersist = this.userRoles.find(c => c.roleId === RoleEnum.Employee);
    const isHrRolePersist = this.userRoles.find(c => c.roleId === RoleEnum.HR_Admin);
    if ((isAdminRolePersist !== null && isAdminRolePersist !== undefined && isEmployeeRolePersist !== null && isEmployeeRolePersist !== undefined) && (isHrRolePersist === null || isHrRolePersist === undefined)) {
      let userRole: UserRoles = new UserRoles();
      userRole.roleId = 3;
      let role: Role = new Role();
      role.id = 3;
      role.roleName = 'HR Admin';
      role.priority = 2;
      role.createdById = isAdminRolePersist.role.createdById;
      role.createdDate = isAdminRolePersist.role.createdDate;
      userRole.role = role;
      this.userRoles.push(userRole);
      this.userRoles.sort((a, b) => (a.role.priority > b.role.priority) ? 1 : -1);
    }
  }
}
