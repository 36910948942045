<div class="row">
  <div class="col-md-12">
    <svg class="bi bi-compass" title="Switcher"  style="color: black;z-index: 2000; margin-left: 5px; font-size: 22px; cursor: pointer;" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M8 15.016a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13zm0 1a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15z"/>
      <path d="M6 1a1 1 0 0 1 1-1h2a1 1 0 0 1 0 2H7a1 1 0 0 1-1-1zm.94 6.44l4.95-2.83-2.83 4.95-4.95 2.83 2.83-4.95z"/>
    </svg>
    <span style="font-size: 11px; margin-left: 3px;">Switch to Client from here.</span>
    <span class="float-right close-icon" (click)="close()" style="font-size: 18px; font-weight: bold; cursor: pointer;">&times;</span>
  </div>
</div>
<div class="card" style="margin-top: 2px;">
  <div class="card-body">
    <!-- <div class="row" *ngIf="isApplicableRoleSwitcher!==null && isApplicableRoleSwitcher!==undefined && isApplicableRoleSwitcher === true">
      <div class="col-md-12">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Select Role</mat-label>
          <mat-select (selectionChange)="onRoleChanges($event.value)" [(ngModel)]="selectedRoleId">
            <mat-option *ngFor="let role of userRoles" [value]="role.roleId">{{role.role.roleName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div> -->
    <div class="row" *ngIf="isApplicableClientSwitcher!==null && isApplicableClientSwitcher!==undefined && isApplicableClientSwitcher === true">
      <div class="col-md-12">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Select Client</mat-label>
          <mat-select (selectionChange)="onClientChange($event.value)" [(ngModel)]="selectedClientId" 
          [disabled]="isDisableClienDropdown"
          >
            <mat-option>--Select--</mat-option>
            <mat-option *ngFor="let client of clients" [value]="client.id">{{client.clientLegalName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
