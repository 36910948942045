import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
  } from '@angular/common/http';
  import { Injectable } from '@angular/core';
  import { Observable } from 'rxjs';
  
  const httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    }),
  };
  
  @Injectable({
    providedIn: 'root',
  })
  export class TokenInterceptor implements HttpInterceptor {
  
    constructor() {}
 
    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
      
      const token = localStorage.getItem('token');
            
      if (token) {
         
        const modifiedRequest = req.clone({
          headers: req.headers
            .set('Authorization', 'Bearer ' + token)            
        });
        return next.handle(modifiedRequest);
      } else {
         
        const modifiedRequest = req.clone({
          headers: req.headers,
         
        });
        return next.handle(modifiedRequest);
      }
    }
  }