import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Ltisettings } from 'src/app/_models/ltisettings';

@Injectable({
  providedIn: 'root'
})
export class LtiSettingsService {
  baseLtiUrl = environment.baseApiUrl;
  private _ltiService = new Subject<Ltisettings>();
  ltiService$ = this._ltiService.asObservable();

  constructor(private http: HttpClient) { }
  addLti(ltiSettings: Ltisettings) {
    return this.http.post(this.baseLtiUrl + 'ltisettings/', ltiSettings);
  }
  getLti() {
    return this.http.get(this.baseLtiUrl + 'ltisettings');
  }
  private getLtiById(id: number) {
    return this.http.get(this.baseLtiUrl + 'ltisettings/' + id);
  }
  updateLti(ltisettings: Ltisettings) {
    return this.http.put(this.baseLtiUrl + 'ltisettings/' + ltisettings.id, ltisettings);
  }

  setLtiSettings(ltiSettings: Ltisettings) {
    this._ltiService.next(ltiSettings);
  }
}
