import { Routes, RouterModule } from "@angular/router";
import { PageNotFoundComponent } from "./Shared/common/page-not-found/page-not-found.component";
import { NgModule } from "@angular/core";
import { DefaultDashboardComponent } from "./Shared/common/default-dashboard/default-dashboard.component";
import { SelectiveStrategy } from "./pages/Shared/_services/selective-strategy.service";
import { AuthGuard } from "./_guards/auth.guard";
import { CampusRecruiterGuard } from "./_guards/campus-recruiter.guard";
import { AccessDeniedComponent } from "./Shared/common/access-denied/access-denied.component";

const routes: Routes = [
  { path: '', component: DefaultDashboardComponent },
  {
    path: "admin", canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/admin/admin.module").then((m) => m.AdminModule,),
  },
  {
    path: "employee", canActivate: [AuthGuard],
    // data: { preload: true, delay: 5000 },
    loadChildren: () =>
      import("./components/employee/employee.module").then((m) => m.EmployeeModule),
  },
  {
    path: "applicant", canActivate: [AuthGuard],
    // data: { preload: true, delay: 5000 },
    loadChildren: () =>
      import("./components/applicant/applicant.module").then((m) => m.ApplicantModule),
  },
  {
    path: "student", canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/student/student.module").then((m) => m.StudentModule),
  },
  {
    path: "hr", canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/HR/hr.module").then((m) => m.HRModule),
  },
  {
    path: "university", canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/university/university.module").then((m) => m.UniversityModule),
  },
  {
    path: "cr", canActivate: [AuthGuard, CampusRecruiterGuard],
    loadChildren: () =>
      import("./components/campus-recruiter/campus-recruiter.module").then((m) => m.CampusRecruiterModule),
  },
  {
    path: "aqb-reporting", canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/Reporting/reporting.module").then((m) => m.ReportingModule),
  },
  {
    path: "pages", canActivate: [AuthGuard],
    loadChildren: () =>
      import("././pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./components/Auth/auth.module').then((m) => m.AuthModule)
  },


  { path: "pages/client", redirectTo: 'auth/login', pathMatch: "full" },
  // { path: "feedback", component: FeedbackComponent },  // employee module
  // { path: "reporting", component: QuerybuilderComponent },   // reporting module
  // { path: "landing-page", component: LandingPageComponent, },
  // { path: "", redirectTo: "admin", pathMatch: "full" },
  { path: "page-not-found", component: PageNotFoundComponent },
  { path: "access-denied", component: AccessDeniedComponent },
  { path: "**", redirectTo: "/page-not-found" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        // preloadingStrategy: PreloadAllModules,
        preloadingStrategy: SelectiveStrategy,    // custom route preloading strategy
        scrollPositionRestoration: "enabled",
        //initialNavigation: "enabled",
        //initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' ,
        onSameUrlNavigation: "reload",
      }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
