import { Injectable } from '@angular/core';
import * as alertify from 'alertifyjs';

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

  constructor() { }
  confirm(message: string, okCallback: () => any) {
    alertify.confirm('Confirm', message, (e: any) => {
      if (e) {
        okCallback();
      } else { }
    }, () => { });
  }
  success(message: string) {
    alertify.success(message);
  }
  // for alert
  onAlert() {
    alertify.prompt('Input (text):').set('type', 'text');
  }
  error(message: string) {
    alertify.error(message);
  }
  warning(message: string) {
    alertify.warning(message);
  }
  message(message: string) {
    alertify.message(message);
  }
  confirmWarning(header: string, message: string, okCallback: () => any) {
    alertify.confirm().set('labels', { ok: 'Ok' });
    alertify.confirm(header, message, (e: any) => {
      if (e) {
        okCallback();
      } else { }
    }, () => { }).alertify.confirm('labels changed!');
  }
  confirmOkWarning(header: string, message: string, okCallback: () => any) {
    alertify.confirm().set('labels', { ok: 'Ok' });
    alertify.confirm(header, message, function () { okCallback() }
      , function () {
        // put here cancel method
      });
  }
  confirmClientOkWarning(header: string, message: string, okCallback: () => any, onCancel: () => any) {
    alertify.confirm().set('labels', { ok: 'Ok' });
    alertify.confirm(header, message, function () { okCallback() }
      , function () {
        alertify.confirm('Closable: false').set('closable', false);
      });
  }
  confirmWarningOnCancel(header: string, message: string, okCallback: () => any, onCancel: () => any) {
    alertify.confirm().set('labels', { ok: 'Ok' });
    alertify.confirm(header, message, (e: any) => {
      if (e) {
        okCallback();
      } else {

      }
    }, () => { onCancel(); });
  }
  returnConfirmWarningOnCancel(header: string, message: string, okCallback: () => any, onCancel: () => any) {
    alertify.confirm().set('labels', { ok: 'Return' });
    alertify.confirm(header, message, (e: any) => {
      if (e) {
        okCallback();
      } else {

      }
    }, () => { onCancel(); });
  }
  confirmTryWarning(header: string, message: string, okCallback: () => any) {
    alertify.confirm().set('labels', { ok: 'Try Again' });
    alertify.confirm(header, message, (e: any) => {
      if (e) {
        okCallback();
      } else { }
    }, () => { }).alertify.confirm('labels changed!');
  }

  confirmProceedWarning(header: string, message: string, okCallback: () => any) {
    alertify.confirm().set('labels', { ok: 'Proceed' });
    alertify.confirm(header, message, (e: any) => {
      if (e) {
        okCallback();
      } else { }
    }, () => { }).alertify.confirm('labels changed!');
  }

  // warning dialog for Employee landing page to do section
  alertClearWarning(header: string, message: any, okCallback: () => any, onCancel: () => any) {
    alertify.confirm().set('labels', { ok: 'Yes, I do not want to see the alert', cancel: 'No, I want to come back to this, just close the alert' });
    alertify.confirm(header, message, (e: any) => {
      if (e) {
        okCallback();
      } else {

      }
    }, () => { onCancel(); });
  }
}
