export const environment = {
  production: true,
  // win-server-test

  // reportingUrl: 'https://catalyzr.io/catalyzr-reporting',
  // reportingTool: 'https://catalyzr.io/catalyzr-api/queryresultsdemo?clientId=',
  // baseSignalRUrl: 'https://catalyzr.io/catalyzr-api/message',
  // baseAuthSignalRUrl: 'https://catalyzr.io/catalyzr-auth/message',
  // baseReportingSignalRUrl: 'https://catalyzr.io/catalyzr-reporting/message',
  // baseDownloadUrl: 'https://catalyzr.io/catalyzr-api/api/Files/Download?fileName=',
  // baseAuthUrl: 'https://catalyzr.io/catalyzr-auth/api/',
  // baseAppImageUrl: 'https://catalyzr.io/catalyzr-api/',
  // baseS3ImageUrl: 'https://catalyzr.io/catalyzr-api/api/s3/getImage?fileName=',
  // baseAuthApiURL: 'https://catalyzr.io/catalyzr-auth/api/auth/',
  // baseApiUrl: 'https://catalyzr.io/catalyzr-api/api/',
  // baseAuthServerUrl: 'https://catalyzr.io/catalyzr-auth/',
  // deliveryApi: 'https://catalyzr.io/catalyzr-api/api/delivery',
  // launchUriApi: 'https://catalyzr.io/catalyzr-api/api/delivery/',
  // feedbackUrl: 'https://catalyzr.io/catalyzrapp/employee/feedback',
  // employeeUrl: 'https://catalyzr.io/catalyzr-api/api/Employees',
  // baseUrl: 'https://catalyzr.io/catalyzr-api/',
  // taoQuestion: 'https://assessment.catalyzr.io/tao/Main/index?structure=items&ext=taoItems&section=manage_items&uri=https%3A%2F%2Fcatalyzr.io%2Ftao%2Ftaocat.rdf%23i157489052149506',
  // taoAssessments: 'https://assessment.catalyzr.io/tao/Main/index?structure=tests&ext=taoTests&section=manage_tests&uri=https%3A%2F%2Fcatalyzr.io%2Ftao%2Ftaocat.rdf%23i15789847434899258',
  // taoDeliveries: 'https://assessment.catalyzr.io/tao/Main/index?structure=delivery&ext=taoDeliveryRdf&section=manage_delivery_assembly&uri=https%3A%2F%2Fwww.tao.lu%2FOntologies%2FTAODelivery.rdf%23AssembledDelivery',



  reportingUrl: 'https://catalyzr.info/catalyzr-reporting',
  reportingTool: 'https://catalyzr.info/catalyzr-api/queryresultsdemo?clientId=',
  baseSignalRUrl: 'https://catalyzr.info/catalyzr-api/message',
  baseAuthSignalRUrl: 'https://catalyzr.info/catalyzr-auth/message',
  baseReportingSignalRUrl: 'https://catalyzr.info/catalyzr-reporting/message',
  baseDownloadUrl: 'https://catalyzr.info/catalyzr-api/api/Files/Download?fileName=',
  baseAuthUrl: 'https://catalyzr.info/catalyzr-auth/api/',
  baseAppImageUrl: 'https://catalyzr.info/catalyzr-api/',
  baseS3ImageUrl: 'https://catalyzr.info/catalyzr-api/api/s3/getImage?fileName=',
  baseAuthApiURL: 'https://catalyzr.info/catalyzr-auth/api/auth/',
  baseApiUrl: 'https://catalyzr.info/catalyzr-api/api/',
  baseAuthServerUrl: 'https://catalyzr.info/catalyzr-auth/',
  deliveryApi: 'https://catalyzr.info/catalyzr-api/api/delivery',
  launchUriApi: 'https://catalyzr.info/catalyzr-api/api/delivery/',
  feedbackUrl: 'https://catalyzr.info/catalyzrapp/employee/feedback',
  employeeUrl: 'https://catalyzr.info/catalyzr-api/api/Employees',
  baseUrl: 'https://catalyzr.info/catalyzr-api/',
  taoQuestion: 'https://assessment.catalyzr.info/tao/Main/index?structure=items&ext=taoItems&section=manage_items&uri=https%3A%2F%2Fcatalyzr.io%2Ftao%2Ftaocat.rdf%23i157489052149506',
  taoAssessments: 'https://assessment.catalyzr.info/tao/Main/index?structure=tests&ext=taoTests&section=manage_tests&uri=https%3A%2F%2Fcatalyzr.io%2Ftao%2Ftaocat.rdf%23i15789847434899258',
  taoDeliveries: 'https://assessment.catalyzr.info/tao/Main/index?structure=delivery&ext=taoDeliveryRdf&section=manage_delivery_assembly&uri=https%3A%2F%2Fwww.tao.lu%2FOntologies%2FTAODelivery.rdf%23AssembledDelivery',


  taoLoginUserName: 'admin',
  taoLoginPassword: 'admin',
  // baseDomainUrl: 'https://catalyzr.io/catalyzrapp/',
  baseDomainUrl: 'https://catalyzr.info/catalyzrapp/',
  contractUsToEmailAddress: 'Admin@Catalyzr.com',


  addGroup: true,
  groupList: false
};
