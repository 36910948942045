import { Component, OnInit, Inject } from '@angular/core';
import { Client } from 'src/app/_models/client';
import { UserRoles } from 'src/app/_models/userRoles';
import { UiSyncService } from 'src/app/pages/Shared/_services/ui-sync.service';
import { AlertifyService } from 'src/app/pages/Shared/_services/alertify.service';
import { Router } from '@angular/router';
import { AppAuthService } from 'src/app/pages/Shared/_services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoleEnum } from 'src/app/_enums/RoleEnum';
import { Role } from 'src/app/_models/role';
import { ModuleEnum } from 'src/app/_enums/module-enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.css']
})
export class SwitcherComponent implements OnInit {

  clients: Client[] = [];
  userRoles: UserRoles[] = [];
  selectedRoleId = 0;
  selectedClientId = 0;
  isDisableClienDropdown = false;
  isApplicableRoleSwitcher = true;
  isApplicableClientSwitcher = true;
  private subscriptions = new Subscription();
  constructor(
    private uiSyncService: UiSyncService,
    private alertify: AlertifyService,
    private router: Router,
    private authService: AppAuthService,
    public dialogRef: MatDialogRef<SwitcherComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    if (this.data.clients !== null && this.data.clients !== undefined && this.data.clients.length > 0) {
      this.clients = this.data.clients;
    }

    this.selectedRoleId = this.data.selectedRoleId;
    this.selectedClientId = this.data.selectedClientId;

    this.isDisableClienDropdown = this.data.isDisableClientDropdown;
    this.checkAvailabilityForClientSwitcher();
    // this.uiSyncService.onSelectedRoleId.subscribe(selectedRoleId => {
    //   this.checkAvailabilityForClientSwitcher();
    // });



    this.subscriptions.add(this.uiSyncService.onSelectedModule$.subscribe(selectedRoleId => {
      this.checkAvailabilityForClientSwitcher();
    }));



  }
  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    this.subscriptions.unsubscribe();

  }
  // on client change
  onClientChange(event: any) {
    localStorage.setItem('selectedClientId', event);
    this.uiSyncService.selectedClientId = event;
    this.uiSyncService.onClientSelected.next(event);
    this.uiSyncService.onClientSelectionForAQBReporting.next(event);
    this.setSelectedClientName();
  }
  // is applicable client switcher
  checkAvailabilityForClientSwitcher() {
    if (this.uiSyncService.selectedModuleId === ModuleEnum.Admin) {
      this.isApplicableClientSwitcher = true;
    } else {
      this.isApplicableClientSwitcher = false;
    }
  }
  // close dialog
  close() {
    this.dialogRef.close();
  }
  // set selected client name
  setSelectedClientName() {
    if (this.clients !== null && this.clients !== undefined && this.clients.length > 0 && this.selectedClientId > 0) {
      this.clients.forEach(client => {
        if (client.id === this.selectedClientId) {
          this.uiSyncService.onSelectedClientName.next(client.clientLegalName);
          this.uiSyncService.selectedClientName = client.clientLegalName;
          localStorage.setItem('selectedClientName', client.clientLegalName);
        }
      });
    } else {
      this.uiSyncService.onSelectedClientName.next(null);
      this.uiSyncService.selectedClientName = '';
      localStorage.setItem('selectedClientName', '');
    }
  }
}
