import { NgModule } from '@angular/core';


@NgModule({
  declarations: [
    // NavComponent,
    // LogoComponent,
    // RoleSelectionComponent,
    // EmployeeHeaderComponent,
    // SearchComponent,
    // SwitcherComponent,
  ],
  imports: [
    // CommonModule,
    // RouterModule,
    // BsDropdownModule.forRoot(),
    // MatSelectModule,
    // MatFormFieldModule,
    // MatAutocompleteModule,
    // FormsModule,
    // MatCheckboxModule,
    // MatDialogModule,
    // MatButtonModule,
    // ReactiveFormsModule,
    // MatTooltipModule
  ],
  exports: [
    // NavComponent,
    // LogoComponent
  ],
})
export class NavigationModule { }
