import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserConnectionDto } from 'src/app/_models/dto/user-connection-dto';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  )
};

@Injectable({
  providedIn: 'root'
})
export class UserConnectionService {

  baseAPI = environment.baseApiUrl;
  constructor(private http: HttpClient) { }

  getUserActiveConnections(userId: number) {
    return this.http.get(this.baseAPI + 'ManageUserConnections/user/' + userId + '/activeConnections', { headers: httpOptions.headers });
  }
  addUserConnection(inputRequest: UserConnectionDto) {
    return this.http.post(this.baseAPI + 'ManageUserConnections/addUserConnection', inputRequest, { headers: httpOptions.headers });
  }
  updateUserConnection(inputRequest: UserConnectionDto) {
    return this.http.put(this.baseAPI + 'ManageUserConnections/updateUserConnection', inputRequest, { headers: httpOptions.headers });
  }

  // saveUserConnection(inputRequest :UserConnectionDto ) {

  //     this.addUserConnection(inputRequest).subscribe(res => {
  //       console.log('User Connection Saved: ', res);
  //     }, error => {
  //      console.error(error);
  //     });
   
  // }

  // deactiveUserConnection(inputRequest : UserConnectionDto) {  
  //     this.updateUserConnection(inputRequest).subscribe(res => {
  //       console.log('User Connection Updated: ', res);
  //     }, error => {
  //      console.error(error);
  //     });
 
  // }
}
