import { DeliveryDto } from '../DeliveryDto';

export class EmployeeDeliveriesOutputDto {
    public catalyzrPersonId: string;
    public clientId: number;
    public employeeId: string;
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public employeeName: string;
    public czPersonId: number;
    public employeePrimaryEmail: string;
    public deliveries: DeliveryDto[] = new Array();
    public encryptedCatalyzrPersonId: string;
    public encryptedClientId: string;
    public personType: string;
    public schoolName: string;
    public degreeType: string;
    public gpa: number;
    public major: string;
    public targetGraduationDate: number;
    public GetEmployeeName() {
        if (this.middleName !== null && this.middleName !== undefined && this.middleName !== '') {
            this.employeeName = this.firstName + ' ' + this.middleName + ' ' + this.lastName;
        } else {
            this.employeeName = this.firstName + ' ' + this.lastName;
        }

    }
}