import { HttpHeaders, HttpClient, HttpParams, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EmployeeUploadRequestDto } from 'src/app/_models/EmployeeModels/EmployeeUploadRequestDto';
import { EmployeeOLFOrphanDto } from 'src/app/_models/dto/employeeolforphandto';
import { EmployeeOrphanInputRequestDto } from 'src/app/_models/dto/employeeorphaninputrequestdto';
import { Observable } from 'rxjs';
import { EmployeeInviteRequestDto } from 'src/app/_models/dto/employeeinviterequestdto';
import { EmployeeProfileDto } from 'src/app/_models/dto/employeeprofiledto';
import { EmployeeSummariesOutputDto } from 'src/app/_models/dto/employee-summaries-output-dto';
import { PaginatedResult } from 'src/app/_models/pagination/pagination';
import { map } from 'rxjs/operators';
import { EmployeeNewInvitaionOutputDto } from 'src/app/_models/dto/employeenewinvitationoutputdto';

const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  )
};
@Injectable({
  providedIn: 'root'
})
export class EmployeeUploadService {

  baseAPI = environment.baseApiUrl;
  downloaOlfOrphanEmployeesdUrl = environment.baseApiUrl + 'EmployeeUploads/DownloadOLFOrphanDetails/';
  downloaInvitationdUrl = environment.baseApiUrl + 'EmployeeUploads/DownloadEmployeeInvitationReport/';
  downloaEmpErrorSummaries = environment.baseApiUrl + 'EmployeeUploads/DownloadEmployeeErrorSummaries/';
  constructor(private http: HttpClient) { }

  saveEmployeeInEmployeeStaging(employeeUploadRequest: EmployeeUploadRequestDto) {

    // tslint:disable: no-unused-expression
    // const empTest: EmployeeStaging[] = new Array();
    // let anEmp: EmployeeStaging;
    // anEmp.employeeId = '1';
    console.log(employeeUploadRequest);

    return this.http.post(this.baseAPI + 'EmployeeUploads', employeeUploadRequest, { headers: httpOptions.headers });
  }
  uploadEmployeeFile(employeeStaging: any) {
    return this.http.post(this.baseAPI + 'FileUpload/employee', employeeStaging);
  }
  deleteEmployeeStagings(clientId: number) {
    return this.http.delete(this.baseAPI + 'EmployeeUploads/' + clientId, { headers: httpOptions.headers });
  }
  getEmployeeSummariesByClientId(clientId: number) {
    return this.http.get(this.baseAPI + 'EmployeeUploads/' + clientId, { headers: httpOptions.headers });
  }
  getPaginatedEmployeesByClientId(clientId, searchKey?, page?, itemPerPage?) {
    const paginatedResult: PaginatedResult<EmployeeSummariesOutputDto[]> = new PaginatedResult<EmployeeSummariesOutputDto[]>();

    let params = new HttpParams();

    params = params.append('clientId', clientId);

    if (searchKey !== null && searchKey !== undefined && searchKey !== '') {
      searchKey = encodeURIComponent(searchKey);
      params = params.append('searchKey', searchKey);
    }

    if (page != null && itemPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemPerPage);
    }

    return this.http.get<EmployeeSummariesOutputDto[]>(this.baseAPI + 'EmployeeUploads/getEmployee', { observe: 'response', params })
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
          }
          return paginatedResult;
        })
      );
  }

  updateExemplarStatus(employeeId: string, clientId: number, isCheckedExemplar: boolean, lastModifiedById: number) {


    let params = new HttpParams();

    params = params.append('clientId', clientId.toString());
    params = params.append('employeeId', employeeId);
    params = params.append('isCheckedExemplar', isCheckedExemplar.toString());
    params = params.append('lastModifiedById', lastModifiedById.toString());

    return this.http.get(this.baseAPI + 'EmployeeUploads/update-exemplar-status', { params: params, headers: httpOptions.headers });
  }

  getOrphanEmployees(clientId, searchKey?, page?, itemPerPage?) {
    const paginatedResult: PaginatedResult<EmployeeOLFOrphanDto[]> = new PaginatedResult<EmployeeOLFOrphanDto[]>();

    let params = new HttpParams();

    params = params.append('clientId', clientId);

    if (searchKey != null) {
      params = params.append('searchKey', searchKey);
    }

    if (page != null && itemPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemPerPage);
    }

    return this.http.get<EmployeeOLFOrphanDto[]>(this.baseAPI + 'EmployeeUploads/orphandata', { observe: 'response', params })
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
          }
          return paginatedResult;
        })
      );
  }


  sendUserRegistrationLink(selectedEmployeeIds: number[]) {

    let httpParams = new HttpParams();
    let index = 0;
    selectedEmployeeIds.forEach(id => {
      httpParams = httpParams.append('SelectedEmployees[' + (index++) + ']', id.toString());
    });

    return this.http.get(this.baseAPI + 'EmployeeUploads/SendRegistrationLink', { params: httpParams, headers: httpOptions.headers });

  }
  sendEmployeeEmailVerificationCode(employeeId: string, clientId: number) {
    return this.http.get(this.baseAPI + 'EmployeeUploads/SendVerificationCode/' + employeeId + '/employee/' + clientId + '/client', { headers: httpOptions.headers });
  }
  async getOrphanReport(clientId: number): Promise<EmployeeOLFOrphanDto[]> {
    return await this.http.get<EmployeeOLFOrphanDto[]>(this.baseAPI + 'employeeuploads/orphanreport/' + clientId + '/client', { headers: httpOptions.headers }).toPromise();
  }
  async getOlfDataForEmployee(clientId: number): Promise<any> {
    return await this.http.get<any>(this.baseAPI + 'EmployeeUploads/olfdata/' + clientId + '/client', { headers: httpOptions.headers }).toPromise();
  }
  updateEmployeeOrphan(inputRequest: EmployeeOrphanInputRequestDto) {
    return this.http.put(this.baseAPI + 'EmployeeUploads/updateorphanemployee', inputRequest, { headers: httpOptions.headers });
  }
  getPendingsInvitations(clientId: number) {
    return this.http.get(this.baseAPI + 'EmployeeUploads/PendingEmployeesInvitation/' + clientId + '/client', { headers: httpOptions.headers });
  }
  getClosedInvitations(clientId: number) {
    return this.http.get(this.baseAPI + 'EmployeeUploads/ClosedEmployeesInvitation/' + clientId + '/client', { headers: httpOptions.headers });
  }
  // getEmployeeInvitationDetailsSummaries(headerId: number, clientId: number) {
  //   return this.http.get(this.baseAPI + 'EmployeeUploads/EmployeeInvitationDetailsSummaries/' + headerId + '/header/' + clientId + '/client', { headers: httpOptions.headers });
  // }
  getEmployeeInvitationDetailsSummaries(clientId, headerId, searchKey?, page?, itemPerPage?) {
    const paginatedResult: PaginatedResult<EmployeeNewInvitaionOutputDto[]> = new PaginatedResult<EmployeeNewInvitaionOutputDto[]>();

    let params = new HttpParams();

    params = params.append('clientId', clientId);
    params = params.append('headerId', headerId);

    if (searchKey != null) {
      params = params.append('searchKey', searchKey);
    }

    if (page != null && itemPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemPerPage);
    }

    return this.http.get<EmployeeNewInvitaionOutputDto[]>(this.baseAPI + 'EmployeeUploads/EmployeeInvitationDetailsSummaries', { observe: 'response', params })
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
          }
          return paginatedResult;
        })
      );
  }
  public downloadOlfOrphanEmployeesFile(clientId: number) {
    return this.http.get(this.downloaOlfOrphanEmployeesdUrl + clientId, { headers: httpOptions.headers });
  }
  updateInvitationStatus(employeeInviteRequestDto: EmployeeInviteRequestDto) {
    return this.http.put(this.baseAPI + 'EmployeeUploads/UpdateInvitationStatus', employeeInviteRequestDto, { headers: httpOptions.headers });
  }
  // public downloadEmployeeInvitationFile(headerId: number, status: string): Observable<HttpEvent<Blob>> {
  //   return this.http.request(new HttpRequest('GET', `${this.downloaInvitationdUrl + headerId + '/header/' + status + '/status'}`, null, { reportProgress: true, responseType: 'blob' }));
  // }
  public downloadEmployeeInvitationFile(headerId: number, status: string) {
    return this.http.get(this.downloaInvitationdUrl + headerId + '/header/' + status + '/status', { headers: httpOptions.headers });
  }
  public deleteFile(fileName: string) {
    return this.http.get(this.baseAPI + 'EmployeeUploads/DeleteFile/' + fileName, { headers: httpOptions.headers });
  }
  public downloadEmployeeErrorSummaries(headerId: number): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest('GET', `${this.downloaEmpErrorSummaries + headerId + '/header'}`, null, { reportProgress: true, responseType: 'blob' }));
  }
  getEmployeeErrorSummaries(headerId: number) {
    return this.http.get(this.baseAPI + 'EmployeeUploads/EmployeeErrorSummaries/' + headerId + '/header', { headers: httpOptions.headers });
  }
  // update employee profile data
  updateEmployeeProfile(employee: EmployeeProfileDto, fileUpload: File) {
    const formData: FormData = new FormData();
    if (fileUpload !== null && fileUpload !== undefined) {
      formData.append('ImageUrl', fileUpload, fileUpload.name);
    }
    formData.append('EmployeeId', employee.employeeId);
    formData.append('ClientId', employee.clientId.toString());
    formData.append('CatalyzrPersonId', employee.catalyzrPersonId);
    formData.append('Gender', employee.gender);
    formData.append('Age', employee.age.toString());
    // if (employee.ethnicityId !== null && employee.ethnicityId !== undefined && employee.ethnicityId > 0) {
    //   formData.append('EthnicityId', employee.ethnicityId.toString());
    // }

    return this.http.post(this.baseAPI + 'EmployeeUploads/updateemployeeprofile', formData);
  }

  updateEmpProfile(employee: EmployeeProfileDto) {
    return this.http.post(this.baseAPI + 'EmployeeUploads/updateemployeeprofile', employee);
  }
  async getEmployeeImageUrl(catalyzrPersonId: string): Promise<string> {
    return await this.http.get<string>(this.baseAPI + 'Employees/ImageUrl/' + catalyzrPersonId, { headers: httpOptions.headers }).toPromise();
  }
  // get ethnicities
  getEthnicities() {
    return this.http.get(this.baseAPI + 'Ethnicities', { headers: httpOptions.headers });
  }

  getClientJob(empId: number) {
    return this.http.get(this.baseAPI + 'FunctionUploads/getClientJob/' + empId, { headers: httpOptions.headers });
  }

  // async getAllJobName(param: string): Promise<string[]> {
  //   return await this.http.get<string[]>(this.baseAPI + 'FunctionUploads/getAllJobName/' + param, { headers: httpOptions.headers }).toPromise();
  // }

  getAllJobName(param: string, clientId: number) {
    return this.http.get(this.baseAPI + 'FunctionUploads/getAllJobName', { params: { SearchKey: param, ClientId: clientId.toString() }, headers: httpOptions.headers });
  }
  getRequisitionName(param: string, clientId: number) {
    return this.http.get(this.baseAPI + 'FunctionUploads/getRequisitionName', { params: { SearchKey: param, ClientId: clientId.toString() }, headers: httpOptions.headers });
  }

  getAllFunctionName(param: string) {
    return this.http.get(this.baseAPI + 'EmployeeUploads/getAllFunction/', { headers: httpOptions.headers });
  }

  // get lowest job only 10 by search key
  getClientFunction(clientId: number, searchKey?: string) {
    return this.http.get(this.baseAPI + 'EmployeeUploads/' + clientId + '/getFunction/' + searchKey);
  }
  // takes only 10
  getClientOrganizationCodes(clientId: number, searchKey?: string) {
    return this.http.get(this.baseAPI + 'EmployeeUploads/' + clientId + '/getOrganization/' + searchKey);
  }
  // takes only 10
  getClientLocationCodes(clientId: number, searchKey?: string) {
    return this.http.get(this.baseAPI + 'EmployeeUploads/' + clientId + '/getLocation/' + searchKey);
  }
  // Audit process for all job maping
  mapClientJob(clientId) {
    return this.http.post(this.baseAPI + 'careerRecommendation/mapClientJob?clientId=' + clientId, { clientId });
  }
  getEmployeeUploadedRowCount(clientId: number, connectionId: string) {
    return this.http.get(this.baseAPI + 'fileupload/getEmployeeUploadedRowCount', { params: { ClientId: clientId.toString(), ConnectionId: connectionId }, headers: httpOptions.headers });
  }

  public downloadEmployeeSummaries(clientId: number, downloadFileName: string, searchKey) {

    let params = new HttpParams();

    if (searchKey != null) {
      params = params.append('searchKey', searchKey);
    }
    return this.http.get(this.baseAPI + 'Employees/' + downloadFileName + '/download-employees-summary/' + clientId, { params, headers: httpOptions.headers });
  }

}
