<div id="myModal" class="modal">
  <div class="modal-content-wrapper">
    <div class="modal-content">
      @if(isInformationPopup == true){
      <img class="info" src="assets/images/Information-icon.png" alt="info">
      <p class="login-message">{{message}}</p>
      }@else{
      <img *ngIf="!isSuccess" src="assets/login/failedtocreate_reg.png" alt="Failed">
      <img *ngIf="isSuccess" src="assets/login/complete.png" alt="Success">
      <!-- <p>Your login or password is incorrect, please try again.</p> -->
      <p class="login-message" [ngClass]="isSuccess===false ? 'failed' : ''">{{message}}</p>
      }

      <button class="button btn-color closeBtnTwo" (click)="onClose()">
        {{popupButtonName}}</button>
    </div>
    <div class="modal-close">
      <img class="closeBtn" (click)="onClose()" src="assets/login/close.png" alt="">
    </div>
  </div>
</div>