export enum RoleEnum {
  Admin = 1,
  Employee = 2,
  HR_Admin = 3,
  University_Admin = 5,
  Campus_Admin = 6,
  Applicant = 7,
  Campus_Recruiter = 9,
  Student = 10



}
