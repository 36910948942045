import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppAuthService } from './auth.service';
import { User } from 'src/app/_models/user';
import { RoleComponentPermissionInputRequestDto } from 'src/app/_models/dto/rolecomponentpermissioninputrequestdto';


const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  )
};
@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  currentUser: User = new User();
  userAccessableAllUrls: string[] = new Array();
  baseDomainUrl = environment.baseDomainUrl;
  baseUrl = environment.baseAuthUrl;

  constructor(private http: HttpClient, private authService: AppAuthService) { }

  // get role wise permissions [component list]
  getRoleWisePermissions(clientId : number,roleId: number) {
    return this.http.get(this.baseUrl + 'Permissions/RoleWisePermissions/client/'+clientId+'/role/' + roleId, { headers: httpOptions.headers });
  }

  getRoleWiseFeatures(clientId : number, roleId: number) {
    return this.http.get(this.baseUrl + 'features/client/'+clientId+ '/role/' + roleId , { headers: httpOptions.headers });
  }

  // enable permission for component
  enablePermissionForComponent(roleComponentPermission: RoleComponentPermissionInputRequestDto) {
   // ;
    return this.http.post(this.baseUrl + 'Permissions/EnablePermissionForComponent/', roleComponentPermission, { headers: httpOptions.headers });
  }
  // disable permission for component
  disablePermissionForComponent(roleComponentPermission: RoleComponentPermissionInputRequestDto) {
  //  ;
    return this.http.post(this.baseUrl + 'Permissions/DisablePermissionForComponent/', roleComponentPermission, { headers: httpOptions.headers });
  }

  // enable permission for all components
  enablePermissionForAllComponent(roleComponentPermission: RoleComponentPermissionInputRequestDto) {
     return this.http.post(this.baseUrl + 'Permissions/EnablePermissionForComponents/', roleComponentPermission, { headers: httpOptions.headers });
   }

    // disable permission for all components
  disablePermissionForAllComponent(roleComponentPermission: RoleComponentPermissionInputRequestDto) {
      return this.http.post(this.baseUrl + 'Permissions/DisablePermissionForComponents/', roleComponentPermission, { headers: httpOptions.headers });
    }


  // check url permission
  checkAccessUrlForUser(url: any, userId: number) {
    
    this.userAccessableAllUrls = [];
    this.currentUser = this.authService.currentUser;
    // get all user accessable urls
    if (this.currentUser !== null && this.currentUser !== undefined) {
      if (this.currentUser.modules.length > 0) {
        this.currentUser.modules.forEach(module => {
          if (module.sections.length > 0) {
            module.sections.forEach(section => {
              this.userAccessableAllUrls.push(section.url);
              if (section.components.length > 0) {
                section.components.forEach(component => {
                  this.userAccessableAllUrls.push(component.url);
                });
              }
            });
          }
        });
      }
    }
    // match with request url
    if (this.userAccessableAllUrls.length > 0) {
      
      this.userAccessableAllUrls.forEach(accessUrl => {
        
        if (accessUrl === url[0].path) {
          return true;
        }
      });
    }
    return false;
  }


  setPermission(clientId : number,roleId : number,setPermissionDto : any) {
  
     return this.http.post(this.baseUrl +'Permissions/client/'+clientId+'/role/'+ roleId , setPermissionDto, { headers: httpOptions.headers });
   }
   getClientModule(clientId : number) {
    return this.http.get(this.baseUrl + 'Modules/getmodulebyclientid/'+clientId, { headers: httpOptions.headers });
  }
}
