import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertifyService } from 'src/app/pages/Shared/_services/alertify.service';
import { AppAuthService } from 'src/app/pages/Shared/_services/auth.service';
import { ProcessManagementService } from 'src/app/pages/Shared/_services/process-management.service';
import { UiSyncService } from 'src/app/pages/Shared/_services/ui-sync.service';
import { ProcessStatusEnum } from 'src/app/_enums/ProcessStatusEnum';
import { RoleEnum } from 'src/app/_enums/RoleEnum';
import { Process } from 'src/app/_models/dto/process';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-file-upload-notification-side-bar',
  templateUrl: './file-upload-notification-side-bar.component.html',
  styleUrls: ['./file-upload-notification-side-bar.component.css']
})
export class FileUploadNotificationSideBarComponent implements OnInit {
  @Input() className = "active";
  isVisibleFileUploadNotificationBar = false;

  // className = '';
  isSideBar = true;
  isLoader = 'loder';
  progress = 0;
  uploadFileName = '';

  processes: Process[] = [];

  @Input() rowProcessCount: number;
  @Input() totalRowCount: number;
  @Input() processType: number;

  ProcessCount: number;
  TotalCount: number;
  ProcessType: number;
  private subscriptions = new Subscription();
  constructor(private authService: AppAuthService,
    private processManagementService: ProcessManagementService,
    private alertify: AlertifyService,
    private uiSyncService: UiSyncService
  ) {
    this.isVisibleFileUploadNotificationBar = this.authService.selectedRoleId === RoleEnum.Admin ? true : false;

    // set process
    this.subscriptions.add(this.uiSyncService.setProcess.subscribe((res: Process) => {
      var existingProcess = this.processes.find(c => c.id === res.id && c.fileName.toLowerCase() === res.fileName.toLowerCase());
      if (existingProcess) {
        existingProcess.statusName = res.statusName;
        if (res.status === ProcessStatusEnum.Completed || res.status === ProcessStatusEnum.Cancled) {
          existingProcess.processPercent = 100;
          setTimeout(() => {
            this.onCloseProcess(existingProcess)
          }, 3000);

        }
      } else {
        this.processes.push(res);
      }
    }));
  }
  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    this.subscriptions.unsubscribe();

  }

  ngOnInit() {
    this.getUserProcesses();
  }

  // for generating toggle side bar
  toggleLti() {
    if (this.className === '') {
      this.className = 'active';
    } else {
      this.className = '';
    }
  }

  // get user processes
  getUserProcesses() {
    this.processManagementService.getUserAllProcesses(this.authService.currentUser.id).subscribe((res: Process[]) => {
      this.processes = res;
      this.processes.forEach(process => {
        process.statusName = ProcessStatusEnum[process.status];
        process.processPercent = 75;
      });
      console.log(this.processes);
    }, error => {
      this.alertify.error(error);
    });
  }
  // process close after completed
  onCloseProcess(process: Process) {
    var index = this.processes.indexOf(process);
    if (index >= 0) {
      this.processes.splice(index, 1);
    }
  }
}
