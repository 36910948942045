import { ProcessStatusEnum } from "src/app/_enums/ProcessStatusEnum";
import { setInterval } from "timers";

export class Process {
    public id: number;
    public catalyzrPersonId: string;
    public createdById: number;
    public createdOn: Date;
    public lastModifiedById?: number;
    public lastModifiedOn?: Date;
    public fileName: string;
    public fileType: string;
    public status: number = 0;
    public processTypeId: number;
    public uploadedClientId?: number;
    public userId: number;
    public statusName: string;
    public processPercent: number;
  FileUploadType: any;
}