import { TimeObject } from "../assessments/timeObject";

export class HubResponseDto {
  public ClientId: number;
  public ErrorHeaderId: number;
  public ConnectionId: string;
  public CatalyzrPersonId: string;
  public Message: string;
  public ClientJobName: string;
  public ClientJobCode: string;
  public IsECQOn: boolean;
  public IsMasterToggleOn: boolean;
  public TotalRowCount: number;
  public RowProcessCount: number;
  public IsCompletedRowProcess: boolean;
  public FileUploadProcessType: number;

  // file processing
  public ProcessId: number;
  public FileUploadedStatus: number;
  public FileName: string;
  public FileType: string;
  public FileUploadType: number;
  public LogId: number;
  public OnSuccess: boolean;
  public OnFailed: boolean;
  public IsProcessing: boolean;
  public EmployeeIds: string[];
  public ProcessDetailsKey: string;
  public ProcessDetailsStatus: number;

  public AssessmentId: number;
  public RemainingTime: TimeObject;
}
