import { Component, Inject, OnInit, ElementRef, ViewChild, AfterViewInit, Input,Output,EventEmitter } from '@angular/core';
//import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Croper from 'cropperjs';

@Component({
  selector: 'app-image-framing-croping',
  templateUrl: './image-framing-croping.component.html',
  styleUrls: ['./image-framing-croping.component.css']
})
export class ImageFramingCropingComponent implements OnInit, AfterViewInit {

  @Input("imageUrl")ImageUrl:string;
  @Input("fileName")FileName:string;
  @Input("fileFormat")FileFormat:string;
  @Input("showPopup")showPopup:boolean;
  @Output('FileToUpload')FileToUpload= new EventEmitter();
  @Output('closeShowPopup')closeShowPopup= new EventEmitter();

  @ViewChild('image', { static: false }) imageElement: ElementRef;
  croper: Croper;

  fileToUpload: File = null;
  imageUrl: string = 'assets/images/user-image.jpg';
  loadingProfileIamge = true;
  imageDestination = '';
  fileName: string;
  fileFormat: string;
  constructor(
    // public dialogRef: MatDialogRef<ImageFramingCropingComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.imageUrl = this.ImageUrl;
    this.fileName = this.FileName;
    this.fileFormat = this.FileFormat;
  }
  ngAfterViewInit() {
    this.croper = new Croper(this.imageElement.nativeElement, {
      zoomable: true,
      scalable: true,
      aspectRatio: 1,
      crop: () => {
        var canvas = this.croper.getCroppedCanvas();
        this.imageDestination = canvas.toDataURL('image/png');
        this.dataURLtoFile(this.imageDestination);
      }
    });
  }


  close() {
   this.closeShowPopup.emit(false);
  }
  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }
  onApply() {
    console.log(this.fileToUpload);
    this.FileToUpload.emit(this.fileToUpload);
    this.closeShowPopup.emit(false);
  }

  dataURLtoFile(dataurl: any) {
    const arr = dataurl.split(',');
    // const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    this.fileToUpload =  (new File([u8arr], this.fileName, {type: this.fileFormat}));
  }
}
