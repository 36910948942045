import { Injectable } from "@angular/core";
import { PreloadingStrategy, Route } from "@angular/router";
import { of, timer } from "rxjs";
import { Observable } from "rxjs";
import { flatMap } from 'rxjs/operators'

@Injectable()
export class SelectiveStrategy implements PreloadingStrategy {
    preload(route: Route, loadMe: () => Observable<any>): Observable<any> {
        if (route.data && route.data['preload']) {
            var delay: number = route.data['delay']
            if (delay) {
                console.log('preload called on ' + route.path + ' delay is ' + delay);
                return timer(delay).pipe(
                    flatMap(_ => {
                        console.log("Loading now " + route.path);
                        return loadMe();
                    }));
            }
            return loadMe();
        } else {
            console.log('no preload for the path ' + route.path);
            return of(null);
        }
    }


    // preload(route: Route, loadMe: () => Observable<any>): Observable<any> {
    //     if (route.data && route.data['preload']) {
    //         return loadMe();
    //     }
    //     else {
    //         return of(null);
    //     }
    // }
}