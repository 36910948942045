import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EmployeeDeliveriesInputRequestDto } from 'src/app/_models/dto/employeedeliveriesinputrequestdto';
import { Observable } from 'rxjs';
import { PaginatedResult } from 'src/app/_models/pagination/pagination';
import { JobRecommendation } from 'src/app/_models/test-result-view-models/job-recommendation';
import { map } from 'rxjs/operators';
import { VWEmployeeLearningProgram } from 'src/app/_models/ViewModels/vwemployeelearningprogram';
import { EmployeeSkill } from 'src/app/_models/Manage Skill Titles/employee-skill';
import { HRRecommendedJobsForEmployee } from 'src/app/_models/EmployeeModels/hr-recommended-jobs-for-employee';
import { ApplicantRolesAppliedTo } from 'src/app/_models/applicants/applicant-roles-aplied-to';
import { UniversityRecommendedJobsForEmployee } from 'src/app/_models/EmployeeModels/university-recommended-jobs-for-employee';

const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    }
  )
};

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  endpoint = environment.employeeUrl;
  baseUrl = environment.baseApiUrl;
  constructor(private http: HttpClient) { }

  postFile(firstName: string, lastName: string, corporateCode: string, fileUpload: File) {


    const formData: FormData = new FormData();
    formData.append('Image', fileUpload, fileUpload.name);
    formData.append("LastName", lastName);
    formData.append('FirstName', firstName);
    formData.append('CorporateCode', corporateCode);
    return this.http.post(this.endpoint, formData);
  }

  getEmployees() {
    return this.http.get(this.endpoint);
  }
  async getEmployeeDeliveries(employeeDeliveriesInputRequest: EmployeeDeliveriesInputRequestDto): Promise<any> {
    return await this.http.get(this.endpoint + '/EmployeeDeliveries/', { params: { CatalyzrPersonId: employeeDeliveriesInputRequest.catalyzrPersonId, ClientId: employeeDeliveriesInputRequest.clientId.toString() }, headers: httpOptions.headers }).toPromise();
  }
  async getEmployeeByCzPersonAndClient(catalyzrPersonId: string, clientId: number): Promise<any> {
    if (catalyzrPersonId !== null && clientId !== null) {
      return await this.http.get<any>(this.endpoint + '/GetEmployee/', { params: { catalyzrPersonId: catalyzrPersonId, clientId: clientId.toString() }, headers: httpOptions.headers }).toPromise();
    }
  }

  async getEmployeeProfile(catalyzrPersonId: string, clientId: number): Promise<any> {
    return await this.http.get<any>(this.endpoint + '/getEmployeeProfile/', { params: { catalyzrPersonId: catalyzrPersonId, clientId: clientId.toString() }, headers: httpOptions.headers }).toPromise();
  }
  getEmployeeCurrentCareerInfo(catalyzrPersonId, clientId) {
    return this.http.get(this.endpoint + '/getCurrentCareerInfo', { params: { CatalyzrPersonId: catalyzrPersonId, ClientId: clientId }, headers: httpOptions.headers });
  }
  getHRRecommendedCareerInfo(catalyzrPersonId, clientId, jobCode, requisitionId?) {
    let params = new HttpParams();
    params = params.append('clientId', clientId.toString());
    params = params.append('catalyzrPersonId', catalyzrPersonId);
    params = params.append('jobCode', jobCode);
    if (requisitionId != null) {
      params = params.append('requisitionId', requisitionId);

    }
    return this.http.get(this.endpoint + '/getHrRecommendeCareerInfo', { params, headers: httpOptions.headers });
  }

  getUniversityRecommendedCareerInfo(catalyzrPersonId, clientId, jobCode, requisitionId?) {
    let params = new HttpParams();
    params = params.append('clientId', clientId.toString());
    params = params.append('catalyzrPersonId', catalyzrPersonId);
    params = params.append('jobCode', jobCode);
    if (requisitionId != null) {
      params = params.append('requisitionId', requisitionId);

    }
    return this.http.get(this.endpoint + '/getUniversityRecommendeCareerInfo', { params, headers: httpOptions.headers });
  }
  getEmployeeToDo(catalyzrPersonId: string, clientId: number): Observable<any> {
    return this.http.get<any>(this.endpoint + '/getEmployeeTodoAndResult/', { params: { catalyzrPersonId: catalyzrPersonId, clientId: clientId.toString() }, headers: httpOptions.headers });
  }

  getEmployeeJobRecommendations(catalyzrPersonId: string, clientId: number): Observable<any> {
    return this.http.get<any>(this.endpoint + '/getRecommendations/', { params: { catalyzrPersonId: catalyzrPersonId, clientId: clientId.toString() }, headers: httpOptions.headers });
  }

  getUniversityJobRecommendations(catalyzrPersonId: string, clientId: number): Observable<any> {
    return this.http.get<any>(this.endpoint + '/getUniversityRecommendations/', { params: { catalyzrPersonId: catalyzrPersonId, clientId: clientId.toString() }, headers: httpOptions.headers });
  }

  getApplicantRolesAppliedTo(catalyzrPersonId: string, clientId: number) {
    return this.http.get<ApplicantRolesAppliedTo[]>(this.endpoint + '/roles-applied-to/', { params: { catalyzrPersonId: catalyzrPersonId, clientId: clientId.toString() }, headers: httpOptions.headers });
  }
  getApplicantRolesToApplyTo(catalyzrPersonId: string, clientId: number) {
    return this.http.get<any>(this.endpoint + '/roles-to-apply-to/', { params: { catalyzrPersonId: catalyzrPersonId, clientId: clientId.toString() }, headers: httpOptions.headers });
  }

  getPaginatedEmployeeCareerRecommendations(catalyzrPersonId, clientId, hrPersonId, pageNumber?, pageSize?) {
    const paginatedResult: PaginatedResult<JobRecommendation[]> = new PaginatedResult<JobRecommendation[]>();
    let params = new HttpParams();
    params = params.append('ClientId', clientId);
    params = params.append('CatalyzrPersonId', catalyzrPersonId);
    params = params.append('HrPersonId', hrPersonId);
    if (pageNumber != null && pageSize != null) {
      params = params.append('pageNumber', pageNumber);
      params = params.append('pageSize', pageSize);
    }
    return this.http.get<JobRecommendation[]>(this.endpoint + '/getEmployeeCareerRecommendations', { observe: 'response', params })
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
          }
          return paginatedResult;
        })
      );
  }
  getEmployeeTopLearningPrograms(clientId: number, catalyzrPersonId: string) {
    return this.http.get(this.endpoint + '/getEmployeeTopLearningPrograms', { params: { ClientId: clientId.toString(), CatalyzrPersonId: catalyzrPersonId }, headers: httpOptions.headers });
  }
  getPaginatedEmployeeLearningPrograms(clientId: number, catalyzrPersonId: string, cipCode: string, pageNo?, pageSize?) {
    const paginatedResult: PaginatedResult<VWEmployeeLearningProgram[]> = new PaginatedResult<VWEmployeeLearningProgram[]>();

    let params = new HttpParams();
    params = params.append('clientId', clientId.toString());
    params = params.append('catalyzrPersonId', catalyzrPersonId);
    params = params.append('CIPCode', cipCode);
    if (pageNo != null && pageSize != null) {
      params = params.append('pageNumber', pageNo);
      params = params.append('pageSize', pageSize);
    }

    return this.http.get<VWEmployeeLearningProgram[]>(this.endpoint + '/getPaginatedEmployeeLearningPrograms', { observe: 'response', params })
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
          }
          return paginatedResult;
        })
      );
  }

  // get the employee total test count
  getEmployeeTotalTestCount(clientId, catalyzrPersonId) {
    return this.http.get(this.baseUrl + 'employees/getEmployeeCompletedTestCount/' + catalyzrPersonId + '/person/' + clientId + '/client', { headers: httpOptions.headers });
  }
  getPersonAssessmentCompletePercentage(clientId, catalyzrPersonId) {
    return this.http.get(this.baseUrl + 'employees/getPersonAssessmentCompletePercentage/' + catalyzrPersonId + '/person/' + clientId + '/client', { headers: httpOptions.headers });
  }

  getPaginatedHRRecommendedJobsForEmployee(catalyzrPersonId, clientId, hrPersonId, pageNumber?, pageSize?) {
    const paginatedResult: PaginatedResult<HRRecommendedJobsForEmployee[]> = new PaginatedResult<HRRecommendedJobsForEmployee[]>();
    let params = new HttpParams();
    params = params.append('ClientId', clientId);
    params = params.append('CatalyzrPersonId', catalyzrPersonId);
    params = params.append('HRPersonId', hrPersonId);
    if (pageNumber != null && pageSize != null) {
      params = params.append('pageNumber', pageNumber);
      params = params.append('pageSize', pageSize);
    }
    return this.http.get<HRRecommendedJobsForEmployee[]>(this.baseUrl + 'HrLandingPage/getHRRecommendedJobsForEmployeee', { observe: 'response', params })
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
          }
          return paginatedResult;
        })
      );
  }

}
