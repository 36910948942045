import { PermissionsService } from '../pages/Shared/_services/permissions.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad, ActivatedRouteSnapshot, ActivatedRoute, UrlTree, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AlertifyService } from '../pages/Shared/_services/alertify.service';
import { AppAuthService } from '../pages/Shared/_services/auth.service';
import { User } from '../_models/user';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {

  canLoad(route: import("@angular/router").Route, segments: import("@angular/router").UrlSegment[]): boolean | import("rxjs").Observable<boolean> | Promise<boolean> {
    if (this.authService.loggedIn()) {
      // if (this.router.navigate['/login']) {
      // this.router.navigate(['/landing-page']);
      // this.router.navigate(['/pages']);
      // }
      return true;
    }
    else {
      return false;
    }

  }
  currentUser: User = new User();
  constructor(
    private authService: AppAuthService,
    private router: Router,
    private alertify: AlertifyService,
    private permissionService: PermissionsService,
    private route: ActivatedRoute,
  ) { }



  canActivate(data: ActivatedRouteSnapshot): boolean {

    this.authService.setLoggedInUserData();
    this.currentUser = this.authService.currentUser;
    if (this.authService.loggedIn()) {
      let ids = data.data['id'] as Array<number>;
      if (ids !== null && ids !== undefined && ids.length > 0) {
        if (this.authService.routMatching(ids) === true) {
          return true;
        } else {
          this.router.navigate(['/page-not-found']);
          return false;
        }
      } else {
        return true;
      }
      // return true;
    } else {
      this.router.navigate(['/auth/login']);
      return false;
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | import("rxjs").Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    this.authService.setLoggedInUserData();
    this.currentUser = this.authService.currentUser;
    if (this.authService.loggedIn()) {
      let ids = childRoute.data['id'] as Array<number>;
      if (ids !== null && ids !== undefined && ids.length > 0) {
        if (this.authService.routMatching(ids) === true) {
          return true;
        } else {
          this.router.navigate(['/page-not-found']);
          return false;
        }
      } else {
        return true;
      }

      // return true;
    } else {
      this.router.navigate(['/auth/login']);
      return false;
    }
  }


}
