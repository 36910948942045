import { Gender } from './gender';
import { Age } from './age';
import { Race } from './race';
export class EmployeeInfo {

  id: number;
  employeeId: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  currentRoleImageUrl: string;
  hireDate: Date;
  timeInRole: string;
  manager: string;
  performance: string;
  isRelocate?: boolean;
  currentRole: string;
  cq: number;
  age: number;
  gender: string;
  race: Race;
  currentCareer: string;
  careerImageUrl: string;
  currentCareerOSOCCode: string;
  clientJobCode: string;
  seletedEthnicityIds: number[] = [];
  public personType: string;
  public schoolName: string;
  public degreeType: string;
  public gpa: number;
  public major: string;
  public targetGraduationDate: number;
}

