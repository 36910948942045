import { SignalRService } from "src/app/pages/Shared/_services/signalR.service";
import { ClientService } from "src/app/pages/Shared/_services/client.service";
import { UiSyncService } from "src/app/pages/Shared/_services/ui-sync.service";
import { EmployeeUploadService } from "src/app/pages/Shared/_services/employeeUpload.service";
import { RoleEnum } from "../../../_enums/RoleEnum";
import { AlertifyService } from "./alertify.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable, BehaviorSubject, throwError } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Subject } from "rxjs";
import { User } from "src/app/_models/user";
import { Router } from "@angular/router";
import { Role } from "src/app/_models/role";
import { PersonalizatinMistakeNotificationRequestDto } from "src/app/_models/dto/personalizationMistakeNotificationRequestDto";
import { Module } from "src/app/_models/user-permission/module";
import { PaginatedResult } from "src/app/_models/pagination/pagination";
import { UserConnectionService } from "./user-connection.service";
import { UserConnectionDto } from "src/app/_models/dto/user-connection-dto";
import { DeviceDetectorService } from "ngx-device-detector";
import { ModuleEnum } from "src/app/_enums/module-enum";
import { SectionComponent } from "src/app/_models/user-permission/component";
import { EmployeeSummariesOutputDto } from "src/app/_models/dto/employee-summaries-output-dto";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    Accept: "application/json",
  }),
};
@Injectable({
  providedIn: "root",
})
export class AppAuthService {
  baseAuthApi = environment.baseAuthApiURL;
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  currentUser: User;
  currentUserModules: Module[];
  roleId: any;
  public haveRole: any;
  private _companyCodeSource = new Subject<string>();
  companyCode$ = this._companyCodeSource.asObservable();

  private _emailCodeSource = new Subject<string>();
  emailCode$ = this._emailCodeSource.asObservable();

  private _emailSource = new Subject<string>();
  email$ = this._emailSource.asObservable();
  private _companyErrorSource = new Subject<boolean>();
  companyError$ = this._companyErrorSource.asObservable();
  private _verificationErrorSource = new Subject<boolean>();
  verificationError$ = this._verificationErrorSource.asObservable();

  onLoggedIn: BehaviorSubject<User> = new BehaviorSubject(null);
  onLoggedOut: BehaviorSubject<boolean> = new BehaviorSubject(false);

  onUserModulesRefreshed$: Subject<Module[]> = new Subject<Module[]>();
  currentSelectedSelectedRoleId: Subject<number> = new Subject<number>();
  selectedRoleId: number;
  public _selectedRoleId = new Subject<number>();
  currentSelectedRoleId = this._selectedRoleId.asObservable();
  baseAuthUrl = environment.baseAppImageUrl;
  baseS3ImageUrl = environment.baseS3ImageUrl;

  isSelectPeopleTab = false;

  hrNavigateRouteUrl: string[] = [];
  universityNavigateRouteUrl: string[] = [];
  employeeNavigateRouteUrl: string[] = [];

  userSignalRConnectionId = "";
  public _userSignalRConnectionId = new Subject<string>();
  userSignalRConnectionId$ = this._userSignalRConnectionId.asObservable();

  private userComponentSource: BehaviorSubject<SectionComponent[]> =
    new BehaviorSubject<SectionComponent[]>([]);
  userComponent$ = this.userComponentSource.asObservable();
  updateSignalRId: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private alert: AlertifyService,
    private employeeService: EmployeeUploadService,
    private uiSyncService: UiSyncService,
    private clientService: ClientService,
    private userConnectionService: UserConnectionService,
    private deviceDetector: DeviceDetectorService
  ) {}
  login(model: any) {
    return this.http.post(this.baseAuthApi + "login", model).pipe(
      map(async (response: any) => {
        const user = response;
        if (user) {
          localStorage.setItem("token", user.token);
          localStorage.setItem("user", JSON.stringify(user.user));
          this.decodedToken = this.jwtHelper.decodeToken(user.token);
          this.currentUser = user.user;
          this.currentUserModules = this.currentUser.modules;

          localStorage.setItem("user", JSON.stringify(this.currentUser));
          let userObj = localStorage.getItem("user");
          // console.log("User object form local storage : ", userObj);

          this.setUserComponents(this.currentUserModules);

          this.onLoggedIn.next(this.currentUser);
          await this.getEmployeeImageUrl();
        }
      })
    );
  }

  generateOtp(userName: string): Observable<any> {
    const body = { userName };

    return this.http.post(`${this.baseAuthApi}generate-otp`, body, {
      headers: httpOptions.headers,
    });
  }

  verifyOtp(userName: string, otp: string): Observable<any> {
    const body = { userName, otp };
    return this.http
      .post(`${this.baseAuthApi}validate-otp`, body, {
        headers: httpOptions.headers,
      })
      .pipe(
        tap((response: any) => {
          const user = response;
          if (user) {
            localStorage.setItem("token", user.token);
            localStorage.setItem("user", JSON.stringify(user.user));

            this.decodedToken = this.jwtHelper.decodeToken(user.token);
            this.currentUser = user.user;
            this.currentUserModules = this.currentUser.modules;

            localStorage.setItem("user", JSON.stringify(this.currentUser));
            console.log(
              "User object from local storage: ",
              localStorage.getItem("user")
            );

            this.setUserComponents(this.currentUserModules);

            this.onLoggedIn.next(this.currentUser);
          }
        }),
        switchMap(async () => await this.getEmployeeImageUrl())
      );
  }
  getAllComponentsFromModules(modules: Module[]) {
    let allComponents: SectionComponent[] = [];

    modules.forEach((module) => {
      module.sections.forEach((section) => {
        allComponents = allComponents.concat(section.components);
      });
    });

    return allComponents;
  }

  // setUserComponents() {
  //   this.userComponentSource.next(this.getAllComponentsFromModules(this.currentUser.modules));

  // }
  setUserComponents(modules: Module[]) {
    this.userComponentSource.next(this.getAllComponentsFromModules(modules));
  }

  loggedIn() {
    const token = localStorage.getItem("token");
    const isTokenPersists = !this.jwtHelper.isTokenExpired(token);
    if (isTokenPersists) {
      return true;
    } else {
      if (this.currentUser) {
        this.logout();
      }
      return false;
    }
  }
  registerUser(model: any) {
    return this.http.post(this.baseAuthApi + "userregister", model);
  }
  register(model: any) {
    return this.http.post(this.baseAuthApi + "register", model);
  }
  getCatalyzrPerson(
    clientId: Number,
    personId: string
  ): Observable<EmployeeSummariesOutputDto> {
    return this.http.get<EmployeeSummariesOutputDto>(
      this.baseAuthApi + "getPerson/" + clientId + "/" + personId
    );
  }

  bulkUserRegister(model: any) {
    return this.http.post(this.baseAuthApi + "bulk-user-register", model);
  }

  getUserRegisterErrorLog(headerId: number) {
    return this.http.get(
      this.baseAuthApi + "get-user-register-error-log?headerId=" + headerId
    );
  }
  downloadUserUploadErrorSummary(headerId: number, downloadFileName: string) {
    return this.http.get(
      this.baseAuthApi +
        "download-user-register-error-log?headerId=" +
        headerId +
        "&downloadFileName=" +
        downloadFileName,
      { headers: httpOptions.headers }
    );
  }

  deleteFile(fileName: string) {
    return this.http.get(
      this.baseAuthApi + "delete-file?fileName=" + fileName,
      { headers: httpOptions.headers }
    );
  }

  getUserList(): Observable<User[]> {
    return this.http.get<User[]>(this.baseAuthApi + "GetUser");
  }

  getAllUserList(clientId?: number): Observable<User[]> {
    if (clientId === null || clientId === undefined) clientId = 0;
    return this.http.get<User[]>(this.baseAuthApi + "GetAllUser/" + clientId);
  }

  getPaginatedUserList(
    clientId?: any,
    searchKey?: any,
    page?: any,
    itemPerPage?: any
  ) {
    const paginatedResult: PaginatedResult<User[]> = new PaginatedResult<
      User[]
    >();

    let params = new HttpParams();

    if (searchKey != null) {
      searchKey = encodeURIComponent(searchKey);
      params = params.append("searchKey", searchKey);
    }

    if (page != null && itemPerPage != null) {
      params = params.append("pageNumber", page);
      params = params.append("pageSize", itemPerPage);
    }
    if (clientId != null) {
      params = params.append("clientId", clientId);
    }

    return this.http
      .get<User[]>(this.baseAuthApi + "GetAllUser", {
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          paginatedResult.result = response.body;
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResult;
        })
      );
  }

  async getEmployeeImageUrl() {
    await this.employeeService
      .getEmployeeImageUrl(this.currentUser.catalyzrPersonId)
      .then(
        (employeeImageUrl) => {
          if (
            employeeImageUrl !== null &&
            employeeImageUrl !== undefined &&
            employeeImageUrl !== ""
          ) {
            this.uiSyncService.employeeImageUrl.next(employeeImageUrl);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  async getClientLogoUrl(clientId: number) {
    await this.clientService.getClientLogoUrl(clientId).then(
      (logoUrl) => {
        if (logoUrl !== null && logoUrl !== undefined && logoUrl !== "") {
          const logoPath = this.createLogo(logoUrl);
          this.uiSyncService.clientLogoUrl.next(logoPath);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  public createLogo = (serverPath: string) => {
    var data = encodeURIComponent(serverPath);
    return this.baseS3ImageUrl + `${data}`;
  };
  getUserById(id: number): Observable<User> {
    return this.http.get<User>(this.baseAuthApi + "GetUser/" + id);
  }
  updateUser(id: number, user: User) {
    return this.http.put(this.baseAuthApi + "PutUser/" + id, user);
  }

  updateUserPassword(id: number, user: User) {
    return this.http.put(this.baseAuthApi + "UpdateUserPassword/" + id, user, {
      headers: httpOptions.headers,
    });
  }

  verifyCompany(user: User) {
    const id = 1;
    return this.http.put(this.baseAuthApi + "VerifyCompany/" + id, user);
  }

  verifyEmail(user: User) {
    return this.http.put(this.baseAuthApi + "VerifyEmail", user);
  }

  sendCompanyCode(code: string) {
    this._companyCodeSource.next(code);
  }
  sendEmailCode(code: string) {
    this._emailCodeSource.next(code);
  }
  trackCompanyError(err: boolean) {
    this._companyErrorSource.next(err);
  }

  trackVerificationError(err: boolean) {
    this._verificationErrorSource.next(err);
  }

  logout(): boolean {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("selectedModuleId");
    localStorage.removeItem("ViewedJobCode");
    localStorage.removeItem("selectedClientName");
    // this.clearAuthService();
    // this.uiSyncService.clearUiSyncService();
    this.decodedToken = null;
    this.onLoggedOut.next(true);
    this.currentUser = null;
    this.roleId = null;

    return true;
  }

  editProfile(id: number, user: User, fileUpload: File) {
    const formData: FormData = new FormData();
    if (fileUpload !== null && fileUpload !== undefined) {
      formData.append("Image", fileUpload, fileUpload.name);
    }
    formData.append("LastName", user.lastName);
    formData.append("FirstName", user.firstName);
    formData.append("MiddleName", user.middleName);
    formData.append("UserName", user.userName);
    formData.append("Email", user.email);
    formData.append("UserId", id.toString());
    return this.http.post(this.baseAuthApi + "UpdateUserProfile/", formData);
  }
  addRoles(roles: Role) {
    return this.http.post(this.baseAuthApi + "Roles", roles);
  }
  getAllRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(this.baseAuthApi + "Roles");
  }
  checkUserExistanceByEmployeeId(employeeId: string, clientId: number) {
    return this.http.get(
      this.baseAuthApi +
        "CheckUserExistance/" +
        employeeId +
        "/employee/" +
        clientId +
        "/client",
      { headers: httpOptions.headers }
    );
  }

  selectedRoleIdNumber(selectedRoleId: any) {
    this._selectedRoleId.next(selectedRoleId);
  }

  refreshCurrentUserModules(userId: any, roleId: any, isUrl?: boolean) {
    this.roleId = roleId;
    if (this.roleId === RoleEnum.HR_Admin) {
      this.onLogoClick(isUrl);
    } else {
      // only for admin user
      if (this.currentUser) {
        this.http
          .get<Module[]>(
            this.baseAuthApi + "user/" + userId + "/role/" + roleId + "/Modules"
          )
          .subscribe(
            (modules) => {
              this.currentUserModules = modules;
              this.currentUser.modules = modules;

              localStorage.setItem("user", JSON.stringify(this.currentUser));
              this.onUserModulesRefreshed$.next(modules);

              this._selectedRoleId.next(roleId);
              this.selectedRoleId = roleId;
              let currentUrl = this.router.url;
              // this.setRouteUrl();
              if (localStorage.getItem("login")) {
                localStorage.removeItem("login");
                this.onLogoClick();
              } else {
                if (isUrl !== undefined && isUrl === true) {
                } else {
                  this.onLogoClick(isUrl);
                }
              }
            },
            (error) => {
              console.log(error);
              this.alert.warning(
                "No modules found for this user , The role might have been deactivated, Please contact administrator"
              );
              this._selectedRoleId.next(this.haveRole);
            }
          );
      }
    }
  }

  // set the route navigate url//// not used
  setRouteUrl() {
    if (this.selectedRoleId > 0) {
      const currentUrlPath = window.location.pathname;
      if (this.selectedRoleId === RoleEnum.Employee) {
        if (
          currentUrlPath !== null &&
          currentUrlPath !== undefined &&
          currentUrlPath !== "" &&
          currentUrlPath === "/auth/login" &&
          this.currentUser.userRoles.length === 1
        ) {
          this.router.navigate(["/employee/employee-landing-page"]);
        } else {
          if (this.currentUser.userRoles.length > 1) {
            this.router.navigate(["/employee/employee-landing-page"]);
          } else {
            this.router.navigate([currentUrlPath]);
          }
        }
      } else {
        if (
          (currentUrlPath !== null &&
            currentUrlPath !== undefined &&
            currentUrlPath !== "" &&
            currentUrlPath === "/employee-landing-page") ||
          currentUrlPath === "/auth/login"
        ) {
          this.router.navigate(["/pages/default-page"]);
        } else {
          this.router.navigate([currentUrlPath]);
        }
      }
    }
  }

  // user route redirection
  userRouteRedirection(isUrl = false) {
    // const userRoles = this.currentUser.userRoles.sort((a, b) => (a.role.priority > b.role.priority) ? 1 : -1);
    // const selectedRoleId = userRoles[0].roleId;
    // const existingSelectedRoleId = localStorage.getItem('selectedRoleId');
    // if (+existingSelectedRoleId > 0) {
    //   this.selectedRoleId = +existingSelectedRoleId;
    // } else {
    //   this.selectedRoleId = selectedRoleId;
    // }

    this.uiSyncService.onSelectedRoleId.next({
      roleId: this.selectedRoleId,
      isUrl: isUrl,
    });

    const existingSelectedModuleId = localStorage.getItem("selectedModuleId");

    if (+existingSelectedModuleId > 0) {
      this.uiSyncService.setSelectedModule(+existingSelectedModuleId);
      return;
    }

    const userModules = this.currentUser.modules.sort((a, b) =>
      a.priorityOrder > b.priorityOrder ? 1 : -1
    );
    const selectedModuleId = this.getTopPriorityModuleId(userModules);

    this.uiSyncService.setSelectedModule(selectedModuleId);

    this.onLogoClick();
  }

  private getTopPriorityModuleId(modules: Module[]) {
    const userModules = this.currentUser.modules.sort((a, b) =>
      a.priorityOrder > b.priorityOrder ? 1 : -1
    );
    return userModules[0].id;
  }

  // when click on catalyzr logo
  onLogoClick(isUrl: boolean = false) {
    if (this.uiSyncService.selectedModuleId > 0) {
      if (this.isRedirectToEmployeeLandingPage()) {
        this.router.navigate(["/employee/employee-landing-page"]);
      } else if (this.uiSyncService.selectedModuleId === ModuleEnum.HrAdmin) {
        if (isUrl === true) {
        } else {
          this.router.navigate(["/hr/hr-landing-page"]);
        }
      } else if (
        this.uiSyncService.selectedModuleId === ModuleEnum.CampusRecruiter
      ) {
        this.router.navigate(["/cr/campus-recruiter-landing-page"]);
      } else if (this.uiSyncService.selectedModuleId == ModuleEnum.Admin) {
        this.router.navigate(["/admin"]);
      } else if (this.uiSyncService.selectedModuleId == ModuleEnum.Applicant) {
        this.router.navigate(["/applicant/applicant-landing-page"]);
      } else if (this.uiSyncService.selectedModuleId == ModuleEnum.Student) {
        this.router.navigate(["/student/student-landing-page"]);
      } else if (
        this.uiSyncService.selectedModuleId == ModuleEnum.UniversityAdmin
      ) {
        this.router.navigate(["/university/university-landing-page"]);
      }
    } else {
      this.router.navigate(["/auth/login"]);
    }
  }

  isRedirectToEmployeeLandingPage() {
    return this.uiSyncService.selectedModuleId === ModuleEnum.Employee;
  }

  refreshCurrentUserPermissionModules(userId: any) {
    if (this.currentUser) {
      this.http
        .get<Module[]>(this.baseAuthApi + "user/" + userId + "/Modules")
        .subscribe(
          (modules) => {
            this.currentUserModules = modules;
            this.currentUser.modules = modules;
            this.setUserComponents(modules);
            this.updateUserPropertyInLocaStorage("modules", modules);

            this.onUserModulesRefreshed$.next(modules);
          },
          (error) => {}
        );
    }
  }

  updateUserPropertyInLocaStorage(propertyName: string, propertyValue: any) {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      storedUser[propertyName] = propertyValue;
      localStorage.setItem("user", JSON.stringify(storedUser));
    }
  }

  // route matching
  routMatching(ids: number[]) {
    let isMatch = false;
    if (ids === null || ids === undefined || ids === undefined) {
      isMatch = false;
    }
    if (this.selectedRoleId > 0) {
      ids.forEach((id) => {
        if (this.currentUser.modules.length > 0) {
          this.currentUser.modules.forEach((module) => {
            if (module.sections.length > 0) {
              module.sections.forEach((section) => {
                if (section.components.length > 0) {
                  section.components.forEach((component) => {
                    if (
                      component.id === id ||
                      component.parentComponentId === id
                    ) {
                      isMatch = true;
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
    return isMatch;
  }

  // get current user from localstorage and set the selected roleid
  setLoggedInUserData() {
    // get current user
    let jwtHelper = new JwtHelperService();
    const token = localStorage.getItem("token");
    const user: User = JSON.parse(localStorage.getItem("user"));
    if (token) {
      this.decodedToken = this.jwtHelper.decodeToken(token);
    }
    if (user === null || user === undefined) {
      this.router.navigate(["/auth/login"]);
    }
    if (user) {
      this.currentUser = user;
    }
    // get selected role
    const userRoles = this.currentUser.userRoles.sort((a, b) =>
      a.role.priority > b.role.priority ? 1 : -1
    );
    const selectedRoleId = userRoles[0].roleId;
    const existingSelectedRoleId = localStorage.getItem("selectedRoleId");
    if (+existingSelectedRoleId > 0) {
      this.selectedRoleId = +existingSelectedRoleId;
    } else {
      this.selectedRoleId = selectedRoleId;
    }
  }
  setCurrentSelectedRoleId(roleId: number) {
    if (roleId > 0) {
      this.selectedRoleId = +roleId;
      this.currentSelectedSelectedRoleId.next(roleId);
    }
  }
  checkValidUser(inputUser: string) {
    const model: any = {};
    model.inputUser = inputUser;
    return this.http.post(this.baseAuthApi + "CheckValidUser", model, {
      headers: httpOptions.headers,
    });
  }
  sendPasswordRecoveryLink(inputUser: string) {
    const model: any = {};
    model.inputUser = inputUser;
    return this.http.post(this.baseAuthApi + "SendPasswordResetLink", model, {
      headers: httpOptions.headers,
    });
  }
  async checkValidPasswordRestLink(passwordResetLink: string): Promise<User> {
    return await this.http
      .get<User>(this.baseAuthApi + "CheckValidPasswordRecoveryLink/", {
        params: { HashPasswordRecoveryLink: passwordResetLink },
        headers: httpOptions.headers,
      })
      .toPromise();
  }
  updateUserResetPassword(user: User) {
    return this.http.put(this.baseAuthApi + "UpdateUserResetPassword/", user, {
      headers: httpOptions.headers,
    });
  }
  sendPersonalizationMistakeNotification(
    inputRequest: PersonalizatinMistakeNotificationRequestDto
  ) {
    return this.http.post(
      this.baseAuthApi + "SendPersonalizationMistakeNotification",
      inputRequest,
      { headers: httpOptions.headers }
    );
  }

  defaultRoleId(roleId: any) {
    this.roleId = roleId;
  }

  onClickLandingPageChange() {
    const currentRoles = this.currentUser.userRoles;
    let currentSelectedRole: any = new Array();
    currentRoles.forEach((role) => {
      if (role.roleId === this.selectedRoleId) {
        currentSelectedRole.push(role);
      }
    });
    if (currentSelectedRole[0].roleId === RoleEnum.Employee) {
      console.log(currentSelectedRole[0].roleId);
      this.router.navigate(["/employee/employee-landing-page"]);
    } else {
      this.router.navigate(["/admin"]);
    }
  }
  // get cq badge font size
  getCQBadgeFontSize(value: number) {
    if (value) {
      if (value.toString().length <= 2) {
        return 36;
      } else if (value.toString().length > 2) {
        return 26;
      } else {
        return 20;
      }
    }
    return 24;
  }

  // set the hr navigate route url
  setHrNavigateRouteUrl(url: string, isClear = false) {
    if (url) {
      this.hrNavigateRouteUrl =
        isClear === true
          ? []
          : JSON.parse(localStorage.getItem("hrNavigateRoute"));
      if (!this.hrNavigateRouteUrl.includes(url)) {
        this.hrNavigateRouteUrl.push(url);
      }
      localStorage.setItem(
        "hrNavigateRoute",
        JSON.stringify(this.hrNavigateRouteUrl)
      );
    }
  }
  // get the hr navigate route url
  getHrNavigateRouteUrl() {
    this.hrNavigateRouteUrl = JSON.parse(
      localStorage.getItem("hrNavigateRoute")
    );
    if (this.hrNavigateRouteUrl && this.hrNavigateRouteUrl.length > 0) {
      const url = this.hrNavigateRouteUrl.pop();
      localStorage.setItem(
        "hrNavigateRoute",
        JSON.stringify(this.hrNavigateRouteUrl)
      );
      return url;
    } else {
      return "hr/hr-landing-page";
    }
  }

  // set the hr navigate route url
  setUniversityNavigateRouteUrl(url: string, isClear = false) {
    if (url) {
      this.universityNavigateRouteUrl =
        isClear === true
          ? []
          : JSON.parse(localStorage.getItem("universityNavigateRoute"));
      if (!this.universityNavigateRouteUrl.includes(url)) {
        this.universityNavigateRouteUrl.push(url);
      }
      localStorage.setItem(
        "universityNavigateRoute",
        JSON.stringify(this.universityNavigateRouteUrl)
      );
    }
  }
  // get the university navigate route url
  getUniversityNavigateRouteUrl() {
    this.universityNavigateRouteUrl = JSON.parse(
      localStorage.getItem("universityNavigateRoute")
    );
    if (
      this.universityNavigateRouteUrl &&
      this.universityNavigateRouteUrl.length > 0
    ) {
      const url = this.universityNavigateRouteUrl.pop();
      localStorage.setItem(
        "universityNavigateRoute",
        JSON.stringify(this.universityNavigateRouteUrl)
      );
      return url;
    } else {
      return "university/university-landing-page";
    }
  }
  // set the employee navigate route url
  setEmployeeNavigateRouteUrl(url: string, isClear = false) {
    if (url) {
      this.employeeNavigateRouteUrl =
        isClear === true
          ? []
          : JSON.parse(localStorage.getItem("employeeNavigateRoute"));
      if (!this.employeeNavigateRouteUrl.includes(url)) {
        this.employeeNavigateRouteUrl.push(url);
      }
      localStorage.setItem(
        "employeeNavigateRoute",
        JSON.stringify(this.employeeNavigateRouteUrl)
      );
    }
  }
  // get the employee navigate route url
  getEmployeeNavigateRouteUrl() {
    this.employeeNavigateRouteUrl = JSON.parse(
      localStorage.getItem("employeeNavigateRoute")
    );
    if (
      this.employeeNavigateRouteUrl &&
      this.employeeNavigateRouteUrl.length > 0
    ) {
      const url = this.employeeNavigateRouteUrl.pop();
      localStorage.setItem(
        "employeeNavigateRoute",
        JSON.stringify(this.employeeNavigateRouteUrl)
      );
      return url;
    } else {
      return "/employee/employee-landing-page";
    }
  }

  //#region manage user connections
  addUserConnection(userConnectionId?: string) {
    // Ensure currentUser is set and at least one connection ID is present
    const connectionId = userConnectionId || this.userSignalRConnectionId;
    if (this.currentUser && connectionId) {
      console.log("Add userConnectionId", connectionId);

      const inputRequest = new UserConnectionDto();
      inputRequest.userId = this.currentUser.id;
      inputRequest.connectionId = connectionId;
      inputRequest.createdById = this.currentUser.id;
      inputRequest.deviceInfo = `Browser: ${this.deviceDetector.browser}, V: ${this.deviceDetector.browser_version}, OS: ${this.deviceDetector.os}, V: ${this.deviceDetector.os_version}`;
      inputRequest.isActive = true;

      // Call service to add user connection
      this.userConnectionService.addUserConnection(inputRequest).subscribe(
        (res) => {
          console.log("User Connection Saved:", res);
        },
        (error) => {
          this.alert.error(error);
        }
      );
    }
  }

  updateUserConnection(updateSignalRId: string) {
    if (this.currentUser && updateSignalRId) {
      var inputRequest = new UserConnectionDto();
      // inputRequest.userId = this.currentUser.id;
      inputRequest.connectionId = updateSignalRId;
      // inputRequest.createdById = this.currentUser.id;
      inputRequest.isActive = false;

      this.userConnectionService.updateUserConnection(inputRequest).subscribe(
        (res) => {
          console.log("User Connection Updated: ", res);
        },
        (error) => {
          this.alert.error(error);
        }
      );
    }
  }
  deactivateUserConnection() {
    if (this.currentUser && this.userSignalRConnectionId) {
      var inputRequest = new UserConnectionDto();
      inputRequest.connectionId = this.userSignalRConnectionId;
      inputRequest.isActive = false;

      this.userConnectionService.updateUserConnection(inputRequest).subscribe(
        (res) => {
          console.log("User Connection Deactivated: ", res);
        },
        (error) => {
          this.alert.error(error);
        }
      );
    }
  }

  getUserActiveConnections() {
    if (this.currentUser) {
      this.userConnectionService
        .getUserActiveConnections(this.currentUser.id)
        .subscribe(
          (res) => {
            console.log("User Active Connections: ", res);
          },
          (error) => {
            this.alert.error(error);
          }
        );
    }
  }
  updateUserLoginOtpStatus(
    userId: number,
    isEnable: boolean
  ): Observable<boolean> {
    if (userId) {
      const url = `${this.baseAuthApi}${userId}/userId/${isEnable}/isEnable/`;
      return this.http.put<boolean>(url, {}).pipe(
        catchError((error) => {
          console.error("Error updating OTP status:", error);
          return throwError(() => new Error("Failed to update OTP status"));
        })
      );
    } else {
      return throwError(() => new Error("Invalid userId"));
    }
  }

  public downloadUserSummaries(
    downloadFileName: string,
    clienId: number,
    searchKey
  ) {
    let params = new HttpParams();

    if (searchKey != null) {
      params = params.append("searchKey", searchKey);
    }
    return this.http.get(
      this.baseAuthApi +
        "client/" +
        clienId +
        "/DownloadUserList/" +
        downloadFileName,
      { params, headers: httpOptions.headers }
    );
  }
  clearAuthService() {
    this.decodedToken = null;
    this.currentUser = null;
    this.currentUserModules = [];
    this.roleId = null;
    this.haveRole = null;

    this._companyCodeSource.next(null);
    this._emailCodeSource.next(null);
    this._emailSource.next(null);
    this._companyErrorSource.next(false);
    this._verificationErrorSource.next(false);

    this.onLoggedIn.next(null);
    this.onLoggedOut.next(true);

    this.onUserModulesRefreshed$.next([]);
    this.currentSelectedSelectedRoleId.next(null);
    this.selectedRoleId = null;
    this._selectedRoleId.next(null);

    this.hrNavigateRouteUrl = [];
    this.universityNavigateRouteUrl = [];
    this.employeeNavigateRouteUrl = [];
    this.userSignalRConnectionId = "";
    this._userSignalRConnectionId.next("");

    this.userComponentSource.next([]);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("selectedModuleId");
    localStorage.removeItem("ViewedJobCode");
    localStorage.removeItem("selectedClientName");
    localStorage.removeItem("searchKey");
    localStorage.removeItem("career");
    localStorage.removeItem("people");
    localStorage.removeItem("facetedSelection");
    localStorage.removeItem("selectedRoleId");
    localStorage.removeItem("selectedClientId");
    localStorage.removeItem("careerGroupName");
    localStorage.removeItem("hrNavigateRoute");
    localStorage.removeItem("employeeNavigateRoute");
    localStorage.removeItem("universityNavigateRoute");
    sessionStorage.clear();

    this.router.navigate(["/login"]);
  }

  //#endregion
}
