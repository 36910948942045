import { AppAuthService } from 'src/app/pages/Shared/_services/auth.service';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { BehaviorSubject, observable } from 'rxjs';
import { ProcessStatusEnum } from 'src/app/_enums/ProcessStatusEnum';
import { HubResponseDto } from 'src/app/_models/Hubs/hub-response-dto';
import { Process } from 'src/app/_models/dto/process';
import { UiSyncService } from './ui-sync.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private isFirstConnection: boolean = true;
  private downloadableFileSource = new BehaviorSubject<HubResponseDto>(new HubResponseDto());
  downloadableFiles$ = this.downloadableFileSource.asObservable();

  private fileUploadMessageEventSource = new BehaviorSubject<HubResponseDto>(new HubResponseDto());
  fileUploadMessageEvent$ = this.fileUploadMessageEventSource.asObservable();

  signalRConnectionId: string;
  private hubConnection: HubConnection | undefined;
  currentSignalRPath: string;

  constructor(private uiSyncService: UiSyncService, private authService: AppAuthService) { }

  async connect(path: string, withToken: boolean): Promise<void> {
    if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected && this.currentSignalRPath === path) {
      console.log(this.currentSignalRPath, path)
      return;
    }
    const token = localStorage.getItem("token");

    this.hubConnection = new HubConnectionBuilder().withUrl(path, {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
      accessTokenFactory: () => token
    })
      .configureLogging(signalR.LogLevel.Trace)
      .withAutomaticReconnect()
      .build();

    this.hubConnection.keepAliveIntervalInMilliseconds = 30000;    // 30 sec
    this.hubConnection.serverTimeoutInMilliseconds = 60000;   // 1 minutes


    try {
      await this.hubConnection.start();
      console.log('SignalR connected');
      await this.handleConnectionId(this.isFirstConnection, path);
      this.isFirstConnection = false;
      this.hubConnection.onreconnected(() => {
        this.handleConnectionId(false, path);
      });
    } catch (error) {
      console.error('SignalR connection failed:', error);
    }
  }
  private async handleConnectionId(isFirstConnection: boolean, path: string, maxRetries: number = 5, retryDelay: number = 2000): Promise<void> {
    let attempt = 0;

    const tryGetConnectionId = async (): Promise<void> => {
      try {
        // Check the connection state
        if (this.hubConnection?.state === signalR.HubConnectionState.Connected) {
          const connectionId = await this.hubConnection.invoke('getConnectionId');
          if (this.signalRConnectionId != connectionId) {
            if (this.signalRConnectionId) {
              console.warn("Update User Connection: " + this.signalRConnectionId);
              await this.authService.updateUserConnection(this.signalRConnectionId);
            }
            if (connectionId) {
              console.warn("Add User Connection: " + connectionId);
              this.signalRConnectionId = connectionId;
              await this.authService.addUserConnection(connectionId);
              this.manageConnection(connectionId);
              this.currentSignalRPath = path;
            }
          }

        } else {
          console.warn('Connection is not in the "Connected" state. Attempting to reconnect...');
          attempt++;

          if (attempt <= maxRetries) {
            setTimeout(tryGetConnectionId, retryDelay); // Wait and try again
          } else {
            console.error('Max retries reached. Unable to retrieve connection ID.');
          }
        }
      } catch (error) {
        console.error('Failed to retrieve connection ID:', error);
      }
    };

    // Start the connection attempt
    tryGetConnectionId();
  }

  async getConnectionId(attempt: number = 0): Promise<string | null> {
    try {
      if (this.hubConnection?.state === signalR.HubConnectionState.Connected) {
        const connectionId = await this.hubConnection.invoke('getConnectionId');
        console.warn(`Connection is in the "Connected" state. ConnectionId is :${connectionId}`);
        return connectionId;
      } else {
        console.warn(`Connection is not in the "Connected" state. Attempt ${attempt + 1} to reconnect...`);

        if (attempt < 5) {
          // Wait and try again after 2 seconds
          await this.delay(2000);
          return this.getConnectionId(attempt + 1);
        } else {
          console.error('Max retries reached. Unable to retrieve connection ID.');
          return null;
        }
      }
    } catch (error) {
      console.error('Error getting SignalR connection ID:', error);
      return null;
    }
  }

  // Helper function to add a delay
  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  manageConnection(connectionId) {
    this.authService.userSignalRConnectionId = connectionId;
    this.authService._userSignalRConnectionId.next(connectionId);

  }
  async define(methodName: string, newMethod: (...args: any[]) => void): Promise<void> {
    if (this.hubConnection) {
      this.hubConnection.on(methodName, newMethod);
    }
  }

  async invoke(methodName: string, ...args: any[]): Promise<any> {
    if (this.isConnected()) {
      return this.hubConnection.invoke(methodName, ...args);
    }
  }
  removeHandler(methodName: string, method: (...args: any[]) => void): void {
    this.hubConnection.off(methodName, method);
  }

  disconnect(): void {
    console.log("Disconnecting signalR connection...")

    console.log("Is disconnected : " + this.isConnected())

    if (this.isConnected()) {
      this.hubConnection.stop();

      console.log("Disconnected signalR connection...")
    }
  }

  isConnected(): boolean {
    return this.hubConnection && this.hubConnection.state === HubConnectionState.Connected;
  }

  //#region signalR reconnection functionality
  async onReconnected(newMethod: (...args: any[]) => void): Promise<void> {
    if (this.hubConnection) {
      this.hubConnection.onreconnected(async () => {
        newMethod();
        await this.onReconnectedId();
      });
    }
  }

  async onReconnectedId(): Promise<void> {
    if (this.hubConnection) {
      try {
        const connectionId = await this.hubConnection.invoke('getConnectionId');
        console.warn("Update User Connection reconnect: " + connectionId);
        this.handleConnectionId(false, this.currentSignalRPath);
        return connectionId;
      } catch (error) {
        console.error('Failed to retrieve connection ID on reconnection:', error);
      }
    }
  }


  listenDownloadCompletedNotification() {
    this.hubConnection.on("FileDownloadCompleted", (response: HubResponseDto) => {

      this.downloadableFileSource.next(response);
    });
  }

  listenFileUploadMesageEvent() {
    this.hubConnection.on("FileUploadMessageEvents", (response: HubResponseDto) => {

      this.fileUploadMessageEventSource.next(response);
    });
  }

  clearFileUploadMessageSource() {
    this.fileUploadMessageEventSource.next(new HubResponseDto());
  }

  listenPorcessNotificationEvent() {

    this.hubConnection.on('UpdateFileUploadProcess', (data: HubResponseDto) => {
      if (data) {
        let process = new Process();
        process.id = data.ProcessId;
        process.fileName = data.FileName;
        process.fileType = data.FileType;
        process.uploadedClientId = data.ClientId;
        process.status = data.FileUploadedStatus;
        process.statusName = ProcessStatusEnum[data.FileUploadedStatus];
        process.processPercent = 55;

        this.uiSyncService.setProcess.next(process);

        // process icon notification
        if (data.FileUploadedStatus === ProcessStatusEnum.InProgress) {
          this.uiSyncService.isLoadingProcess.next(true);
        } else if (data.FileUploadedStatus === ProcessStatusEnum.Completed) {
          this.uiSyncService.isLoadingProcess.next(false);
        } else if (data.FileUploadedStatus === ProcessStatusEnum.Cancled) {
          this.uiSyncService.isLoadingProcess.next(false);
        } else if (data.FileUploadedStatus === ProcessStatusEnum.Withheld) {
          this.uiSyncService.isLoadingProcess.next(false);
        }


      }
    });
  }

  clearSignalRService(): void {
    if (this.hubConnection && this.hubConnection.state === HubConnectionState.Connected) {
      this.hubConnection.stop()
        .then(() => console.log("SignalR disconnected"))
        .catch(err => console.error("Error disconnecting SignalR :", err));
    }
    this.signalRConnectionId = ''; // Clear the connection ID
    this.downloadableFileSource.next(new HubResponseDto()); // Reset subjects
    this.fileUploadMessageEventSource.next(new HubResponseDto());
    console.log("SignalRService: Cleared all data and reset connection");
  }
}
