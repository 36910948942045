import { ErrorInterceptorProvider } from './_interceptor/error.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppAuthService } from './pages/Shared/_services/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleLoginProvider, SocialAuthServiceConfig  } from '@abacritt/angularx-social-login';
import { HomeComponent } from './Shared/common/home/home.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AlertifyService } from './pages/Shared/_services/alertify.service';
import { LtiSettingsService } from './pages/Shared/_services/lti-settings.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CookieService } from 'ngx-cookie-service';
import { SharedModule } from './Shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { LazyForDirective } from './_directives/lazyFor.directive';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { SelectiveStrategy } from './pages/Shared/_services/selective-strategy.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserConnectionService } from './pages/Shared/_services/user-connection.service';
import { TokenInterceptor } from './_interceptor/token.interceptor';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';


@NgModule({
  schemas: [
    NO_ERRORS_SCHEMA
  ],
  declarations: [
    AppComponent,
    // LandingPageComponent, 
    HomeComponent,    
    // TakeTestComponent,      
    LazyForDirective
    // QuerybuilderComponent,      // reporting module
  ],
  imports: [
    BsDropdownModule.forRoot(),
    FormsModule ,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    NgSelectModule,
    jqxGridModule,
    MatProgressBarModule,
    CommonModule,
    MatSidenavModule
  ],
  providers: [
    ErrorInterceptorProvider,
    AppAuthService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '640694678765-q6pis418b0hngnngifmmdifj6llfup5n.apps.googleusercontent.com'
            )
          }
        ]
      } as SocialAuthServiceConfig,
    },
    AlertifyService,
    LtiSettingsService,
    MatDatepickerModule,
    { provide: 'clientId', useValue: 10 },
    DatePipe,
    CookieService,
    SelectiveStrategy,
    DeviceDetectorService,
    UserConnectionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}
