import { UiSyncService } from 'src/app/pages/Shared/_services/ui-sync.service';
import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppAuthService } from './pages/Shared/_services/auth.service';
import { User } from './_models/user';
import { SignalRService } from './pages/Shared/_services/signalR.service';
import { HubResponseDto } from './_models/Hubs/hub-response-dto';
import { environment } from 'src/environments/environment';
import { AlertifyService } from './pages/Shared/_services/alertify.service';
import { RoleEnum } from './_enums/RoleEnum';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { AutoLogoutService } from './pages/Shared/_services/auto-logout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  jwtHelper = new JwtHelperService();
  loading = false;
  messageUrl = environment.baseSignalRUrl;
  isShowMessagePopup = false;
  popupMessage = '';
  private subscriptions = new Subscription();
  constructor(
    private authService: AppAuthService,
    private signalRService: SignalRService,
    private alertify: AlertifyService,
    private uiSyncService: UiSyncService,
    private autoLogoutService: AutoLogoutService
  ) {
    // re-generate the signlR when loggedout
    this.subscriptions.add(this.uiSyncService.onLoggedOut.subscribe(res => {

      if (res) {
        this.authService.deactivateUserConnection();
      }
    }));

    // start the signalR process
    if (this.authService.loggedIn()) {
      if (this.signalRService.isConnected() && this.signalRService.currentSignalRPath === this.messageUrl) {
        this.defineSignaling();
      } else {
        this.signalRService.connect(this.messageUrl, true).then(() => {
          this.defineSignaling();
        });
      }
      this.signalRService.onReconnected(() => {
        this.defineSignaling();
      });
    }
  }
  ngOnInit() {
    const token = localStorage.getItem('token');
    const user: User = JSON.parse(localStorage.getItem('user'));
    if (token) {
      this.authService.decodedToken = this.jwtHelper.decodeToken(token);
    }
    if (user) {
      this.authService.currentUser = user;
      this.authService.setUserComponents(user.modules);
    }
  }
  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    this.subscriptions.unsubscribe();

  }


  //#region manage user connections

  // star the signalR process

  // define signalR events
  defineSignaling(): void {

    // signalR events
    this.signalRService.define('OnUserResultProcessed', (data: HubResponseDto) => {
      debugger
      this.alertify.message(data.Message);
    });

    this.signalRService.define('OnEmployeeResultProcessed', (data: HubResponseDto) => {
      debugger
      console.log('On OnEmployeeResultProcessed', data);
      console.log('Current user', this.authService.currentUser);
      if (this.authService.currentUser && (this.authService.currentUser.clientId > 0) && (data.ClientId === this.authService.currentUser.clientId) && (data.CatalyzrPersonId === this.authService.currentUser.catalyzrPersonId)) {
        this.uiSyncService.isEmployeeResultProcessed.next(true);
        // this.alertify.message(data.Message);
        this.isShowMessagePopup = true;
        this.popupMessage = data.Message;
        console.log('Message', data.Message);
      }
    });

  }
  //#endregion

  // close the popup dialog
  onClose(event: any) {
    this.isShowMessagePopup = false;
  }
}
