<!-- User details section end -->
<div
  id="employeeDetails"
  [ngClass]="
    isShowProfile === true
      ? 'employee_detsils_pop employee-active'
      : 'employee_detsils_pop'
  "
>
  <div class="employee_detsils_border">
    <div class="closeIcon">
      <!-- <i (click)="closeEmployee()"  class="fa fa-times" aria-hidden="true"></i> -->
      <span (click)="closeEmployee()">&times;</span>
    </div>
    <div class="employee_detsils_left text-center">
      <h5 class="employee-name">
        {{ employeeInfo.firstName }} {{ employeeInfo.lastName }}
      </h5>
      <div class="employee_detsils_photo">
        <img
          *ngIf="loadingProfileIamge"
          class="card-img-top-user"
          src="assets/images/image-loading.gif"
          alt="loading"
        />
        <img
          [hidden]="loadingProfileIamge"
          class="img-fluid"
          (load)="onLoadProfileIamge()"
          [src]="imageUrl"
          alt="Employee Profile Image"
        />
      </div>
      <div class="employee_change_photo">
        <label title="Upload Photo">
          <a
            ><img
              src="assets/employee/camera.png"
              alt="image"
              class="img-fluid"
            />
            Change Image</a
          >
          <input
            #myInput
            type="file"
            id="upload"
            accept="image/*"
            class="form-control"
            (change)="handleFileInput($event.target.files)"
            placeholder="Upload Photo"
          />
        </label>
      </div>
      <h5 class="employee-name-web">
        {{ employeeInfo.firstName }} {{ employeeInfo.lastName }}
      </h5>
      <small
        class="current-career"
        *ngIf="isEmployee"
        (click)="onClickCurrentCareer()"
      >
        {{ employeeInfo.currentCareer }}</small
      >
      <p *ngIf="isEmployee" class="career-recommedation-cart color-two">
        CQ: {{ employeeInfo.cq }}
      </p>
    </div>
    <div class="employee_detsils_right">
      <div class="employee_right_top_info">
        <ul *ngIf="isEmployee">
          <li>Hire Date : {{ employeeInfo.hireDate | date }}</li>
          <li>Time in Role : {{ employeeInfo.timeInRole }}</li>
          <li
            class="managerName"
            *ngIf="
              hasManager === true &&
              employeeInfo.manager !== null &&
              employeeInfo.manager !== undefined &&
              employeeInfo.manager !== ''
            "
          >
            Manager : {{ employeeInfo.manager }}
          </li>
          <li
            class="managerName"
            *ngIf="
              hasManager === false &&
              (employeeInfo.manager === null ||
                employeeInfo.manager === undefined ||
                employeeInfo.manager === '')
            "
          >
            Manager :
            <span style="color: red; font-size: 15px; margin-top: 1px">
              &nbsp;No Employee Manager Found.</span
            >
          </li>
          <li>Performance : {{ employeeInfo.performance }}</li>
          <li>
            Willing to Relocate?
            <input
              type="checkbox"
              class="example-margin"
              color="primary"
              [checked]="isRelocateY"
              (change)="WillRelocate(true, $event.target.checked)"
            />Y
            <!-- [checked]="!employeeInfo.isRelocate" -->
            <input
              type="checkbox"
              class="example-margin"
              color="warn"
              [checked]="isRelocateN"
              (change)="WillRelocate(false, $event.target.checked)"
            />N
          </li>
          <li class="current-career">
            Current Career : {{ employeeInfo.currentCareer }}
          </li>
        </ul>

        <ul *ngIf="isApplicant">
          <li>Person Type : {{ employeeInfo.personType }}</li>
          <li>School : {{ employeeInfo.schoolName }}</li>
          <li>Major : {{ employeeInfo.major }}</li>
          <li>GPA : {{ employeeInfo.gpa }}</li>

          <li>Degree Type : {{ employeeInfo.degreeType }}</li>
          <li>
            Target Graduation Date : {{ employeeInfo.targetGraduationDate }}
          </li>
        </ul>
        <!-- default block -->
        <ul *ngIf="isApplicant == false && isEmployee == false">
          <li>Hire Date : {{ employeeInfo.hireDate | date }}</li>
          <li>Time in Role : {{ employeeInfo.timeInRole }}</li>
          <li
            *ngIf="
              hasManager === true &&
              employeeInfo.manager !== null &&
              employeeInfo.manager !== undefined &&
              employeeInfo.manager !== ''
            "
          >
            Manager : {{ employeeInfo.manager }}
          </li>
          <li
            *ngIf="
              hasManager === false &&
              (employeeInfo.manager === null ||
                employeeInfo.manager === undefined ||
                employeeInfo.manager === '')
            "
          >
            Manager :
            <span style="color: red; font-size: 15px; margin-top: 1px">
              &nbsp;No Employee Manager Found.</span
            >
          </li>
          <li>Performance : {{ employeeInfo.performance }}</li>
          <li>
            Willing to Relocate?
            <input
              type="checkbox"
              class="example-margin"
              color="primary"
              [checked]="isRelocateY"
              (change)="WillRelocate(true, $event.target.checked)"
            />Y
            <!-- [checked]="!employeeInfo.isRelocate" -->
            <input
              type="checkbox"
              class="example-margin"
              color="warn"
              [checked]="isRelocateN"
              (change)="WillRelocate(false, $event.target.checked)"
            />N
          </li>
        </ul>
        <div class="employee_logout">
          <a (click)="logout()">logout</a>
        </div>
      </div>
      <div>
        <h6>OTP Enabled Status</h6>
        <mat-slide-toggle
          style="margin-right: 10px; z-index: 0; padding-bottom: 10px"
          color="primary"
          [checked]="otpLoginEnabled"
          (change)="onToggleChange($event)"
          [disabled]="disableToggole"
        >
        </mat-slide-toggle>
      </div>
      <div class="employee_self_info">
        <h6>Self-identified Information:</h6>
        <p>
          While not mandatory, this data is useful in scoring assessments
          against statical norms, and thus providing more accurate career
          recommendations.
        </p>
      </div>
      <div class="employee_self_info_bottom">
        <div class="employee_self_inner_bottom">
          <form class="d-flex justify-content-between">
            <!-- <div class="employee_self_inner_form">
              <label>Age:</label>
              <div class="select">
                <select (change)="onAgeChange($event.target.value)" [(value)]="selectedAge" name="slct" id="slct"
                  class="employeeCategory">
                  <option selected disabled>Select Age</option>
                  <option [value]="age" *ngFor="let age of ages">{{age}}</option>
                </select>
              </div>
            </div> -->
            <!-- <div class="employee_self_inner_form">
              <label> Identified Gender:</label>
              <div class="select">
                <select [(ngModel)]="selectedGender" name="slct" id="slct" class="employeeCategory">
                  <option selected disabled>Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
            </div> -->
            <div class="employee_self_inner_form">
              <label class="race-etinicity">Race/Ethinicity:</label>
              <div class="select">
                <form #raceIds="ngForm">
                  <ng-multiselect-dropdown
                    *ngIf="
                      ethnicities !== null &&
                      ethnicities !== undefined &&
                      ethnicities.length > 0
                    "
                    [placeholder]="'Select Race'"
                    [settings]="dropdownSettings"
                    [data]="data"
                    [(ngModel)]="selectedRaces"
                    name="selectedRace"
                    #selectedRace="ngModel"
                    (onDeSelect)="onDeSelectItem($event)"
                    (onSelect)="onItemSelect($event)"
                  >
                  </ng-multiselect-dropdown>
                </form>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="employee_logout-bottom">
        <a (click)="logout()">logout</a>
      </div>
    </div>
  </div>
</div>
<!-- User details section end -->

<app-image-framing-croping
  *ngIf="showPopup"
  (FileToUpload)="afterApplyCrop($event)"
  (closeShowPopup)="closeShowPopup($event)"
  [showPopup]="showPopup"
  [imageUrl]="imageUrl"
  [fileName]="fileName"
  [fileFormat]="fileFormat"
></app-image-framing-croping>
