import { UiSyncService } from 'src/app/pages/Shared/_services/ui-sync.service';
import { EmployeeUploadService } from 'src/app/pages/Shared/_services/employeeUpload.service';
import { AppAuthService } from 'src/app/pages/Shared/_services/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertifyService } from 'src/app/pages/Shared/_services/alertify.service';
import { ClientService } from 'src/app/pages/Shared/_services/client.service';
import { Client } from 'src/app/_models/client';

@Component({
  selector: 'app-default-dashboard',
  templateUrl: './default-dashboard.component.html',
  styleUrls: ['./default-dashboard.component.css']
})
export class DefaultDashboardComponent implements OnInit {
  re = /\ /gi;
  hashEmpCorporateCode: string;
  client: Client = new Client();

  constructor(
    private router: Router,
    private authService: AppAuthService,
    private employeeService: EmployeeUploadService,
    private uiSyncService: UiSyncService,
    private cookie: CookieService, private spinner: NgxSpinnerService,
    private alertify: AlertifyService, private clientService: ClientService,
  ) {
    this.loggedId();
  }

  ngOnInit() {
    
  }


  // use auth guard
  private async loggedId() {
    if (this.authService.loggedIn()) {
      await this.loadModules();
      // if (this.hashEmpCorporateCode !== null && this.hashEmpCorporateCode !== undefined && this.hashEmpCorporateCode !== '') {
      //   await this.getClientByHasCorporateCode();
      // }
      return true;
    } else {
      // if (this.hashEmpCorporateCode !== null && this.hashEmpCorporateCode !== undefined && this.hashEmpCorporateCode !== '') {
      //   localStorage.setItem('hashCorporateCode', this.hashEmpCorporateCode);
      //   // this.router.navigate(["/landing-page"]);
      //   this.router.navigate(['/auth/login']);
      //   return false;
      // } else {
      //   this.router.navigate(["/auth/login"]);
      //   return false;
      // }
      this.router.navigate(["/auth/login"]);
      return false;
    }
  }

  async getClientByHasCorporateCode() {
    // this.router.navigate(['/landing-page']);
    this.router.navigate(['/auth/login']);
    if (this.hashEmpCorporateCode !== null && this.hashEmpCorporateCode !== undefined && this.hashEmpCorporateCode !== '') {
      await this.clientService.getClientByHasCorporateCode(this.hashEmpCorporateCode).then(response => {
        this.client = response;
        if (this.client !== null && this.client !== undefined) {
          // this.router.navigate(['/pages/default-page']);
          this.router.navigate(['/admin']);
        } else {
          this.alertify.confirmTryWarning('Invalid', 'The URL you have provided is not for a valid Employer, please see you have provided correct URL or contact with your employer.', () => {
            localStorage.removeItem('hashCorporateCode');
            // this.router.navigate(['/landing-page']);
            this.router.navigate(['/auth/login']);
          });
          localStorage.removeItem('hashCorporateCode');
        }
      }, error => {
        this.alertify.confirmTryWarning('Invalid', 'The URL you have provided is not for a valid Employer, please see you have provided correct URL or contact with your employer.', () => {
          localStorage.removeItem('hashCorporateCode');
        });
        localStorage.removeItem('hashCorporateCode');
      });
    }
  }

  // role wise module loading
  async loadModules() {
    this.authService.onLogoClick();
    if (this.authService.currentUser) {
      await this.authService.getEmployeeImageUrl();
    }
    if (localStorage.getItem('FromEmployeeProfile')) {
      localStorage.removeItem('FromEmployeeProfile');
      this.uiSyncService.onToggleEmployeeProfile.next(true);
    }
  }
}
