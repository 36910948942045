import { OrganizationFacetContainer } from './../../../_models/employeeJobRecommandation/organizationDto';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RecommendedJobFilter } from 'src/app/_models/employeeJobRecommandation/recommendedJobFilter';
import { Observable } from 'rxjs';
import { ClientJobSearchDto } from 'src/app/_models/employeeJobRecommandation/clientJobSearchDto';
import { map } from 'rxjs/operators';
import { FacetOrganizationDataDto } from 'src/app/_models/employeeJobRecommandation/organizationDto';
import { FacetLocationDataDto, LocationFacetContainer } from 'src/app/_models/employeeJobRecommandation/locationDto';
import { FacetFunctionDataDto, FunctionFacetContainer } from 'src/app/_models/employeeJobRecommandation/functionDto';
import { QuotientChield } from 'src/app/_models/employeeJobRecommandation/quotientDto';
import { ClientJobRequisitionSearchDto } from 'src/app/_models/applicants/ClientJobRequisitionSearchDto';



const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  )
};

@Injectable({
  providedIn: 'root'
})

export class DeliveryToEmployeeService {
  baseAPI = environment.baseApiUrl;


  constructor(
    private http: HttpClient
  ) { }



  async GetEmployeeByCatalyzrPersonIdAndClientId(catalyzrPersonId: string, clientId: any): Promise<any> {
    return await this.http.get<any>(
      this.baseAPI + 'employees/active/',
      {
        params: { catalyzrPersonId, clientId },
        headers: httpOptions.headers
      }).toPromise();

  }

  makeResultSeen(resultId: number, clientId: number, catalyzrPersonId: string) {
    return this.http.get(this.baseAPI + 'employees/result/isSeen/' + resultId + '/' + clientId + '/' + catalyzrPersonId);
  }


  getEmployeeFacetedSection(filter: RecommendedJobFilter): Observable<ClientJobSearchDto> {

    let params = new HttpParams();
    params = params.append('clientId', filter.clientId.toString());
    params = params.append('personId', filter.personId.toString());


    if (filter.currentPage != null && filter.currentPage > 0) {
      params = params.append('currentPage', filter.currentPage.toString());
    }
    if (filter.itemPerPage != null && filter.itemPerPage > 0) {
      params = params.append('itemPerPage', filter.itemPerPage.toString());
    }


    if (filter.function != null && filter.function.length > 0) {
      params = params.append('function', filter.function.join(','));
    }
    if (filter.location != null && filter.location.length > 0) {
      params = params.append('location', filter.location.join(','));
    }
    if (filter.organization != null && filter.organization.length > 0) {
      params = params.append('organization', filter.organization.join(','));
    }
    if (filter.quotient != null && filter.quotient.length > 0) {
      params = params.append('quotient', filter.quotient.join(','));
    }
    if (filter.jobHints != null && filter.jobHints !== undefined) {
      params = params.append('jobHints', filter.jobHints);
    }

    return this.http.get<ClientJobSearchDto>(this.baseAPI + 'careerRecommendation/getEmployeeFacetedSection', { observe: 'response', params })
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }


  getRecommendedJob(filter: RecommendedJobFilter): Observable<ClientJobSearchDto> {

    let params = new HttpParams();
    params = params.append('clientId', filter.clientId.toString());
    params = params.append('personId', filter.personId.toString());


    if (filter.currentPage != null && filter.currentPage > 0) {
      params = params.append('currentPage', filter.currentPage.toString());
    }
    if (filter.itemPerPage != null && filter.itemPerPage > 0) {
      params = params.append('itemPerPage', filter.itemPerPage.toString());
    }


    if (filter.function != null && filter.function.length > 0) {
      params = params.append('function', filter.function.join(','));
    }
    if (filter.location != null && filter.location.length > 0) {
      params = params.append('location', filter.location.join(','));
    }
    if (filter.organization != null && filter.organization.length > 0) {
      params = params.append('organization', filter.organization.join(','));
    }
    if (filter.quotient != null && filter.quotient.length > 0) {
      params = params.append('quotient', filter.quotient.join(','));
    }
    if (filter.jobHints != null && filter.jobHints !== undefined) {
      params = params.append('jobHints', filter.jobHints);
    }

    if (filter.selectedClientJobCode && filter.selectedOSOCCode) {
      params = params.append('selectedClientJobCode', filter.selectedClientJobCode);
      params = params.append('selectedOSOCCode', filter.selectedOSOCCode);
    }
    return this.http.get<ClientJobSearchDto>(this.baseAPI + 'careerRecommendation/getEmployeeJobSection', { observe: 'response', params })
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  getRecommendedJobRequisition(filter: RecommendedJobFilter): Observable<ClientJobRequisitionSearchDto> {

    let params = new HttpParams();
    params = params.append('clientId', filter.clientId.toString());
    params = params.append('personId', filter.personId.toString());


    if (filter.currentPage != null && filter.currentPage > 0) {
      params = params.append('currentPage', filter.currentPage.toString());
    }
    if (filter.itemPerPage != null && filter.itemPerPage > 0) {
      params = params.append('itemPerPage', filter.itemPerPage.toString());
    }


    if (filter.function != null && filter.function.length > 0) {
      params = params.append('function', filter.function.join(','));
    }
    if (filter.location != null && filter.location.length > 0) {
      params = params.append('location', filter.location.join(','));
    }
    if (filter.organization != null && filter.organization.length > 0) {
      params = params.append('organization', filter.organization.join(','));
    }
    if (filter.quotient != null && filter.quotient.length > 0) {
      params = params.append('quotient', filter.quotient.join(','));
    }
    if (filter.jobHints != null && filter.jobHints !== undefined) {
      params = params.append('jobHints', filter.jobHints);
    }

    if (filter.selectedClientJobCode && filter.selectedOSOCCode && filter.selectedRequisitionId) {
      params = params.append('selectedClientJobCode', filter.selectedClientJobCode);
      params = params.append('selectedOSOCCode', filter.selectedOSOCCode);
      params = params.append('selectedRequisitionId', filter.selectedRequisitionId);
    }
    if (filter.isApplied) {
      params = params.append("isApplied", filter.isApplied.toString())
    }
    if (filter.selectedSkillId) {
      params = params.append("selectedSkillId", filter.selectedSkillId);
    }

    return this.http.get<ClientJobRequisitionSearchDto>(this.baseAPI + 'careerRecommendation/applicant-requisition-section', { observe: 'response', params })
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  mapClientJob(clientId) {
    return this.http.post(this.baseAPI + 'careerRecommendation/mapClientJob?clientId=' + clientId, { clientId });
  }

  // get employee organization faceted section data
  getEmployeeQuotientFacetedSection(filter: RecommendedJobFilter): Observable<QuotientChield[]> {

    let params = new HttpParams();
    params = params.append('clientId', filter.clientId.toString());
    params = params.append('personId', filter.personId.toString());


    if (filter.currentPage != null && filter.currentPage > 0) {
      params = params.append('currentPage', filter.currentPage.toString());
    }
    if (filter.itemPerPage != null && filter.itemPerPage > 0) {
      params = params.append('itemPerPage', filter.itemPerPage.toString());
    }


    if (filter.function != null && filter.function.length > 0) {
      params = params.append('function', filter.function.join(','));
    }
    if (filter.location != null && filter.location.length > 0) {
      params = params.append('location', filter.location.join(','));
    }
    if (filter.organization != null && filter.organization.length > 0) {
      params = params.append('organization', filter.organization.join(','));
    }
    if (filter.quotient != null && filter.quotient.length > 0) {
      params = params.append('quotient', filter.quotient.join(','));
    }
    if (filter.jobHints != null && filter.jobHints !== undefined) {
      params = params.append('jobHints', filter.jobHints);
    }

    if (filter.parentItemPerPage && filter.parentCurrentPage) {
      params = params.append('ParentCurrentPage', filter.parentCurrentPage.toString());
      params = params.append('ParentItemPerPage', filter.parentItemPerPage.toString());
    }


    return this.http.get<QuotientChield[]>(this.baseAPI + 'careerRecommendation/getEmployeeQuotientFacetedSection', { observe: 'response', params })
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  // get employee organization faceted section data
  getEmployeeOrgFacetedSection(filter: RecommendedJobFilter): Observable<OrganizationFacetContainer> {

    let params = new HttpParams();
    params = params.append('clientId', filter.clientId.toString());
    params = params.append('personId', filter.personId.toString());


    if (filter.currentPage != null && filter.currentPage > 0) {
      params = params.append('currentPage', filter.currentPage.toString());
    }
    if (filter.itemPerPage != null && filter.itemPerPage > 0) {
      params = params.append('itemPerPage', filter.itemPerPage.toString());
    }


    if (filter.function != null && filter.function.length > 0) {
      params = params.append('function', filter.function.join(','));
    }
    if (filter.location != null && filter.location.length > 0) {
      params = params.append('location', filter.location.join(','));
    }
    if (filter.organization != null && filter.organization.length > 0) {
      params = params.append('organization', filter.organization.join(','));
    }
    if (filter.quotient != null && filter.quotient.length > 0) {
      params = params.append('quotient', filter.quotient.join(','));
    }
    if (filter.jobHints != null && filter.jobHints !== undefined) {
      params = params.append('jobHints', filter.jobHints);
    }

    if (filter.parentItemPerPage && filter.parentCurrentPage) {
      params = params.append('ParentCurrentPage', filter.parentCurrentPage.toString());
      params = params.append('ParentItemPerPage', filter.parentItemPerPage.toString());
    }


    return this.http.get<OrganizationFacetContainer>(this.baseAPI + 'careerRecommendation/getEmployeeOrgFacetedSection', { observe: 'response', params })
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  // get employee location faceted section data
  getEmployeeLocFacetedSection(filter: RecommendedJobFilter): Observable<LocationFacetContainer> {

    let params = new HttpParams();
    params = params.append('clientId', filter.clientId.toString());
    params = params.append('personId', filter.personId.toString());


    if (filter.currentPage != null && filter.currentPage > 0) {
      params = params.append('currentPage', filter.currentPage.toString());
    }
    if (filter.itemPerPage != null && filter.itemPerPage > 0) {
      params = params.append('itemPerPage', filter.itemPerPage.toString());
    }


    if (filter.function != null && filter.function.length > 0) {
      params = params.append('function', filter.function.join(','));
    }
    if (filter.location != null && filter.location.length > 0) {
      params = params.append('location', filter.location.join(','));
    }
    if (filter.organization != null && filter.organization.length > 0) {
      params = params.append('organization', filter.organization.join(','));
    }
    if (filter.quotient != null && filter.quotient.length > 0) {
      params = params.append('quotient', filter.quotient.join(','));
    }
    if (filter.jobHints != null && filter.jobHints !== undefined) {
      params = params.append('jobHints', filter.jobHints);
    }
    if (filter.parentItemPerPage && filter.parentCurrentPage) {
      params = params.append('ParentCurrentPage', filter.parentCurrentPage.toString());
      params = params.append('ParentItemPerPage', filter.parentItemPerPage.toString());
    }


    return this.http.get<LocationFacetContainer>(this.baseAPI + 'careerRecommendation/getEmployeeLocFacetedSection', { observe: 'response', params })
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  // get employee function faceted section data
  getEmployeeFuncFacetedSection(filter: RecommendedJobFilter): Observable<FunctionFacetContainer> {

    let params = new HttpParams();
    params = params.append('clientId', filter.clientId.toString());
    params = params.append('personId', filter.personId.toString());


    if (filter.currentPage != null && filter.currentPage > 0) {
      params = params.append('currentPage', filter.currentPage.toString());
    }
    if (filter.itemPerPage != null && filter.itemPerPage > 0) {
      params = params.append('itemPerPage', filter.itemPerPage.toString());
    }


    if (filter.function != null && filter.function.length > 0) {
      params = params.append('function', filter.function.join(','));
    }
    if (filter.location != null && filter.location.length > 0) {
      params = params.append('location', filter.location.join(','));
    }
    if (filter.organization != null && filter.organization.length > 0) {
      params = params.append('organization', filter.organization.join(','));
    }
    if (filter.quotient != null && filter.quotient.length > 0) {
      params = params.append('quotient', filter.quotient.join(','));
    }
    if (filter.jobHints != null && filter.jobHints !== undefined) {
      params = params.append('jobHints', filter.jobHints);
    }
    if (filter.parentItemPerPage && filter.parentCurrentPage) {
      params = params.append('ParentCurrentPage', filter.parentCurrentPage.toString());
      params = params.append('ParentItemPerPage', filter.parentItemPerPage.toString());
    }

    return this.http.get<FunctionFacetContainer>(this.baseAPI + 'careerRecommendation/getEmployeeFuncFacetedSection', { observe: 'response', params })
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  // get employee organization faceted section load more data
  getEmployeeOrgFacetedSectionLoadMoreData(filter: RecommendedJobFilter): Observable<OrganizationFacetContainer> {

    let params = new HttpParams();
    params = params.append('clientId', filter.clientId.toString());
    params = params.append('personId', filter.personId.toString());
    params = params.append('SelectedFacetedLevel', filter.selectedFacetLevel);

    if (filter.function != null && filter.function.length > 0) {
      params = params.append('function', filter.function.join(','));
    }
    if (filter.location != null && filter.location.length > 0) {
      params = params.append('location', filter.location.join(','));
    }
    if (filter.organization != null && filter.organization.length > 0) {
      params = params.append('organization', filter.organization.join(','));
    }
    if (filter.quotient != null && filter.quotient.length > 0) {
      params = params.append('quotient', filter.quotient.join(','));
    }

    if (filter.jobHints != null && filter.jobHints !== undefined) {
      params = params.append('jobHints', filter.jobHints);
    }
    if (filter.parentItemPerPage && filter.parentCurrentPage) {
      params = params.append('ParentCurrentPage', filter.parentCurrentPage.toString());
      params = params.append('ParentItemPerPage', filter.parentItemPerPage.toString());
    }

    if (filter.childCurrentPage && filter.childItemPerPage) {
      params = params.append('ChildCurrentPage', filter.childCurrentPage.toString());
      params = params.append('ChildItemPerPage', filter.childItemPerPage.toString());
    }

    return this.http.get<OrganizationFacetContainer>(this.baseAPI + 'careerRecommendation/orgLoadMore', { observe: 'response', params })
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  // get employee location faceted section load more data
  getEmployeeLocFacetedSectionLoadMoreData(filter: RecommendedJobFilter): Observable<LocationFacetContainer> {

    let params = new HttpParams();
    params = params.append('clientId', filter.clientId.toString());
    params = params.append('personId', filter.personId.toString());
    params = params.append('SelectedFacetedLevel', filter.selectedFacetLevel);

    if (filter.function != null && filter.function.length > 0) {
      params = params.append('function', filter.function.join(','));
    }
    if (filter.location != null && filter.location.length > 0) {
      params = params.append('location', filter.location.join(','));
    }
    if (filter.organization != null && filter.organization.length > 0) {
      params = params.append('organization', filter.organization.join(','));
    }
    if (filter.quotient != null && filter.quotient.length > 0) {
      params = params.append('quotient', filter.quotient.join(','));
    }
    if (filter.jobHints != null && filter.jobHints !== undefined) {
      params = params.append('jobHints', filter.jobHints);
    }
    if (filter.parentItemPerPage && filter.parentCurrentPage) {
      params = params.append('ParentCurrentPage', filter.parentCurrentPage.toString());
      params = params.append('ParentItemPerPage', filter.parentItemPerPage.toString());
    }

    if (filter.childCurrentPage && filter.childItemPerPage) {
      params = params.append('ChildCurrentPage', filter.childCurrentPage.toString());
      params = params.append('ChildItemPerPage', filter.childItemPerPage.toString());
    }

    return this.http.get<LocationFacetContainer>(this.baseAPI + 'careerRecommendation/locLoadMore', { observe: 'response', params })
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  // get employee function faceted section load more data
  getEmployeeFuncFacetedSectionLoadMoreData(filter: RecommendedJobFilter): Observable<FunctionFacetContainer> {

    let params = new HttpParams();
    params = params.append('clientId', filter.clientId.toString());
    params = params.append('personId', filter.personId.toString());
    params = params.append('SelectedFacetedLevel', filter.selectedFacetLevel);

    if (filter.function != null && filter.function.length > 0) {
      params = params.append('function', filter.function.join(','));
    }
    if (filter.location != null && filter.location.length > 0) {
      params = params.append('location', filter.location.join(','));
    }
    if (filter.organization != null && filter.organization.length > 0) {
      params = params.append('organization', filter.organization.join(','));
    }
    if (filter.quotient != null && filter.quotient.length > 0) {
      params = params.append('quotient', filter.quotient.join(','));
    }
    if (filter.jobHints != null && filter.jobHints !== undefined) {
      params = params.append('jobHints', filter.jobHints);
    }
    if (filter.parentItemPerPage && filter.parentCurrentPage) {
      params = params.append('ParentCurrentPage', filter.parentCurrentPage.toString());
      params = params.append('ParentItemPerPage', filter.parentItemPerPage.toString());
    }

    if (filter.childCurrentPage && filter.childItemPerPage) {
      params = params.append('ChildCurrentPage', filter.childCurrentPage.toString());
      params = params.append('ChildItemPerPage', filter.childItemPerPage.toString());
    }

    return this.http.get<FunctionFacetContainer>(this.baseAPI + 'careerRecommendation/funcLoadMore', { observe: 'response', params })
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }
}
