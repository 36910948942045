import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { EmployeeResultProcessDto } from 'src/app/_models/dto/EmployeeProcessStatusDto';
import { ClientProcessDto } from 'src/app/_models/dto/client-process';
import { ProcessDto } from 'src/app/_models/dto/process-dto';
import { ProcessInputRequestDto } from 'src/app/_models/dto/process-input-request-dto';
import { ProcessStatusCount } from 'src/app/_models/dto/process-status-count';
import { PaginatedResult } from 'src/app/_models/pagination/pagination';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  )
};

@Injectable({
  providedIn: 'root'
})
export class ProcessManagementService {
  baseAPI = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  getUserAllProcesses(userId: number) {
    return this.http.get(this.baseAPI + 'Process/userProcess', { params: { UserId: userId.toString() }, headers: httpOptions.headers });
  }
  isProcessRunningForUser(userId: number) {
    return this.http.get(this.baseAPI + 'Process/IsProcessRunning/' + userId + '/user', { headers: httpOptions.headers });
  }
  async setProcess(processData: ProcessDto): Promise<boolean> {
    return await this.http.post<boolean>(this.baseAPI + 'Process/setProcess', processData, { headers: httpOptions.headers }).toPromise();
  }
  async updateProcess(processInputRequestDto: ProcessInputRequestDto): Promise<boolean> {
    return await this.http.post<boolean>(this.baseAPI + 'Process/updateProcess', processInputRequestDto, { headers: httpOptions.headers }).toPromise();
  }

  async getEmployeeProcessStatus(clientId: number): Promise<EmployeeResultProcessDto> {
    return await this.http.get<EmployeeResultProcessDto>(this.baseAPI + 'Process/RunningProcess/' + clientId + '/client', { headers: httpOptions.headers }).toPromise();
  }

  isProcessRunningForClientOrUser(clientId?: number, userId?: number) {
    return this.http.get(this.baseAPI + 'Process/IsProcessRunning/' + clientId + '/client/' + userId + '/user', { headers: httpOptions.headers });
  }
  isProcessRunningForClient(clientId: number) {
    return this.http.get(this.baseAPI + 'Process/IsProcessRunning/' + clientId + '/client', { headers: httpOptions.headers });
  }
  isProcessRunning(clientId: number, processTypeId: number) {
    return this.http.get(this.baseAPI + 'Process/IsProcessRunning/' + clientId + '/client/' + processTypeId + '/type', { headers: httpOptions.headers });
  }


  uploadResultFile(result: any) {
    return this.http.post(this.baseAPI + 'FileUpload/result', result);
  }

  getClientProcesses(
    clientId: number,
    status: number,
    searchKey?,
    page?,
    itemPerPage?,

  ) {
    const paginatedResult: PaginatedResult<ClientProcessDto[]> = new PaginatedResult<ClientProcessDto[]>();

    let params = new HttpParams();

    if (searchKey != null) {
      params = params.append("searchKey", searchKey);
    }
    if (page != null && itemPerPage != null) {
      params = params.append("pageNumber", page);
      params = params.append("pageSize", itemPerPage);
    }
    if (status != null && status != undefined) {
      params = params.append("status", status.toString());
    }

    return this.http
      .get<ClientProcessDto[]>(this.baseAPI + `processes/client/${clientId}`, {
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          paginatedResult.result = response.body;
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(response.headers.get("Pagination"));
          }
          return paginatedResult;
        })
      );
  }

  getProcessStatusCount(clientId: number) {
    return this.http.get<ProcessStatusCount[]>(this.baseAPI + 'processes/status/client/' + clientId, { headers: httpOptions.headers });
  }

}
