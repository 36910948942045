import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
  EventEmitter,
  Output,
} from "@angular/core";
import {
  MatDialogRef,
  MatDialog,
  MatDialogConfig,
} from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AlertifyService } from "src/app/pages/Shared/_services/alertify.service";
import { AppAuthService } from "src/app/pages/Shared/_services/auth.service";
import { DeliveryToEmployeeService } from "src/app/pages/Shared/_services/delivery-to-employee.service";
import { EmployeeService } from "src/app/pages/Shared/_services/employee.service";
import { EmployeeUploadService } from "src/app/pages/Shared/_services/employeeUpload.service";
import { UiSyncService } from "src/app/pages/Shared/_services/ui-sync.service";
import { FileSizeEnum } from "src/app/_enums/FileSizeEnum";
import { EmployeeDeliveriesOutputDto } from "src/app/_models/dto/employeedeliveriesoutputdto";
import { EmployeeInfo } from "src/app/_models/EmployeeModels/employee-info";
import { Ethnicity } from "src/app/_models/ethnicity";
import { environment } from "src/environments/environment";
import { EmployeeHeaderComponent } from "../employee-header/employee-header.component";
import { ImageFramingCropingComponent } from "../image-framing-croping/image-framing-croping.component";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { event } from "jquery";
import { SignalRService } from "src/app/pages/Shared/_services/signalR.service";
import { PersonTypeEnum } from "src/app/_enums/person-type-enum";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

@Component({
  selector: "app-employee-profile",
  templateUrl: "./employee-profile.component.html",
  styleUrls: ["./employee-profile.component.css"],
})
export class EmployeeProfileComponent implements OnInit {
  @ViewChild(ImageFramingCropingComponent, { static: false })
  imageFramingCropingComponent: ImageFramingCropingComponent;
  loading = true;

  @Input("isShowEmployeeProfile") isShowProfile = false;
  @Output("onClosePopup") employeeProfileClose = new EventEmitter<boolean>();
  @Output("onLoggedOut") loggedOut = new EventEmitter<boolean>();

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  loadingProfileIamge = true;
  loadingCareerImage = true;
  isEmployee = false;
  isApplicant = false;

  baseUrl = environment.baseS3ImageUrl;
  careerImageUrl: any = "assets/images/career-details-default-image-large.png";
  employeeInfo: EmployeeInfo = new EmployeeInfo();
  hasManager = null;
  currentUser: any;
  employee: EmployeeDeliveriesOutputDto;
  baseAuthUrl = environment.baseAppImageUrl;
  jobRecommendations: any[];
  testResult: any;
  deliveries: any;
  jobRecommendationsLenght: boolean;
  workValues: any;
  catalyzrPersonId: string;
  clientId: any;
  isSelectAboutUs: boolean;
  // isSelectAboutUs: boolean;
  isSelectLogin: boolean;
  loginUser: any;

  isRelocate: any;
  isRelocateY: boolean;
  isRelocateN: boolean;

  selectedAge: number;
  selectedGender: string = "";
  selectedEthnicityId: number;
  imageUrl: any = "assets/images/user-image.jpg";
  userImage: any;
  fileToUpload: File = null;
  fileName: string;
  fileFormat: string;
  ages: number[] = [];
  ethnicities: Ethnicity[] = [];
  selectedEthnicityIds: number[] = [];

  selectedRaces = [];
  data = [];

  showPopup: boolean = false;
  otpLoginEnabled: boolean;
  disableToggole: boolean;

  constructor(
    private deliveryService: DeliveryToEmployeeService,
    private authService: AppAuthService,
    private route: Router,
    public dialog: MatDialog,
    public alertifyService: AlertifyService,
    private employeeService: EmployeeUploadService,
    private uiSyncService: UiSyncService,
    private employeeCoreService: EmployeeService,
    private sanitizer: DomSanitizer,
    private signalRService: SignalRService
  ) {
    this.currentUser = this.authService.currentUser;
    this.catalyzrPersonId = this.currentUser.catalyzrPersonId;
    this.clientId = this.currentUser.clientId;
    if (this.currentUser.catalyzrPersonId) {
      this.otpLoginEnabled = this.currentUser.isOTPEnabled;
    } else {
      this.otpLoginEnabled = false;
      this.disableToggole = true;
    }
  }
  async ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      itemsShowLimit: 1,
      enableCheckAll: false,
      defaultOpen: false,
    };
    this.employeeAges();
    await this.GetEmployeeByCatalyzrPersonIdAndClientId();
    if (this.authService.currentUser) {
      await this.authService.getEmployeeImageUrl();
    }
    this.getEthnicities();
  }
  onItemSelect(item: any) {
    console.log(this.selectedRaces);
    console.log(item);
    this.selectedEthnicityIds.push(item.id);
    this.onChangeProfile();
  }
  onDeSelectItem(event: any) {
    for (let i = 0; i <= this.selectedEthnicityIds.length; i++) {
      if (this.selectedEthnicityIds[i] === event.id) {
        this.selectedEthnicityIds.splice(i, 1);
      }
    }
    this.onChangeProfile();
  }
  // ages
  employeeAges() {
    for (let i = 18; i <= 99; i++) {
      this.ages.push(i);
    }
  }

  setPersonByType(personType: string) {
    if (
      PersonTypeEnum.Applicant == personType ||
      PersonTypeEnum.Student == personType
    ) {
      this.isApplicant = true;
      this.isEmployee = false;
    } else if (
      PersonTypeEnum.Employee == personType ||
      PersonTypeEnum.ContingentLabor == personType
    ) {
      this.isApplicant = false;
      this.isEmployee = true;
    }
  }

  async GetEmployeeByCatalyzrPersonIdAndClientId() {
    await this.employeeCoreService
      .getEmployeeProfile(this.catalyzrPersonId, this.clientId)
      .then(
        (response) => {
          if (response) {
            this.employeeInfo = response;

            this.setPersonByType(response.personType);

            if (
              this.employeeInfo.currentCareerOSOCCode === null ||
              this.employeeInfo.currentCareerOSOCCode === undefined
            ) {
              this.employeeInfo.currentCareerOSOCCode = "";
            }
            if (
              this.employeeInfo.careerImageUrl === null ||
              this.employeeInfo.careerImageUrl === undefined ||
              this.employeeInfo.careerImageUrl === ""
            ) {
              this.careerImageUrl =
                "assets/images/career-details-default-image-large.png";
            } else {
              this.careerImageUrl = this.createS3ImgPath(
                this.employeeInfo.careerImageUrl + FileSizeEnum.small
              );
            }
            if (
              this.employeeInfo.manager === null ||
              this.employeeInfo.manager === undefined ||
              this.employeeInfo.manager === ""
            ) {
              this.hasManager = false;
            } else {
              this.hasManager = true;
            }
            if (this.employeeInfo.isRelocate === null) {
              this.isRelocateY = false;
              this.isRelocateN = false;
            } else {
              if (this.employeeInfo.isRelocate === true) {
                this.isRelocateY = true;
                this.isRelocateN = false;
              } else {
                this.isRelocateY = false;
                this.isRelocateN = true;
              }
            }
            this.selectedEthnicityIds = response.seletedEthnicityIds;
          }
          let employee: EmployeeDeliveriesOutputDto =
            new EmployeeDeliveriesOutputDto();
          employee.firstName = this.currentUser.firstName;
          employee.middleName = this.currentUser.middleName;
          employee.lastName = this.currentUser.lastName;
          employee.catalyzrPersonId = this.currentUser.catalyzrPersonId;
          if (response != null && response != undefined) {
            employee.personType = response.personType;
            employee.schoolName = response.schoolName;
            employee.degreeType = response.degreeType;
            employee.gpa = response.gPA;
            employee.major = response.major;
            employee.targetGraduationDate = response.targetGraduationDate;
          }

          if (
            employee.middleName !== null &&
            employee.middleName !== undefined &&
            employee.middleName !== ""
          ) {
            employee.employeeName =
              employee.firstName +
              " " +
              employee.middleName +
              " " +
              employee.lastName;
          } else {
            employee.employeeName =
              employee.firstName + " " + employee.lastName;
          }
          this.employee = employee;
          //this.selectedGender = response.gender;
          //this.selectedAge = response.age;
          if (response != null || response != undefined) {
            this.selectedEthnicityId = response.ethnicityId;
            if (
              response.imageUrl !== null &&
              response.imageUrl !== undefined &&
              response.imageUrl !== ""
            ) {
              this.userImage = response.imageUrl;
              if (
                this.userImage !== null &&
                this.userImage !== undefined &&
                this.userImage !== ""
              ) {
                this.imageUrl = this.createImgPath(this.userImage);
              }
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    this.fileName = this.fileToUpload.name;
    this.fileFormat = this.fileName.slice(
      ((this.fileName.lastIndexOf(".") - 1) >>> 0) + 2
    );
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
      this.showPopup = true;
      //this.uploadProfileImage(this.imageUrl, this.fileName, this.fileFormat);
    };
    reader.readAsDataURL(this.fileToUpload);

    // this.onChangeProfile();
  }
  public createS3ImgPath = (serverPath: string) => {
    var data = encodeURIComponent(serverPath);
    return this.baseUrl + `${data}`;
  };
  public createImgPath = (serverPath: string) => {
    var data = encodeURIComponent(serverPath);
    return this.baseUrl + `${data}`;
  };

  // this is a function to close dialog

  // closeDialog() {
  //   const empProfile = this.prepareSave();
  //   this.employeeService.updateEmpProfile(empProfile).subscribe(async (response) => {
  //     await this.authService.getEmployeeImageUrl();
  //   }, error => {
  //     console.log(error);
  //   });
  // }
  onAgeChange(value) {
    this.selectedAge = +value;
  }
  private prepareSave(): any {
    const profileForm = new FormData();
    // if (this.selectedGender === 'undefined' || this.selectedGender == null) {
    //   profileForm.append('gender', '');
    // } else {
    //   profileForm.append('gender', this.selectedGender);
    // }
    profileForm.append("catalyzrPersonId", this.currentUser.catalyzrPersonId);
    profileForm.append("employeeId", this.employeeInfo.employeeId);
    profileForm.append("clientId", this.currentUser.clientId);

    if (
      this.selectedEthnicityIds != undefined &&
      this.selectedEthnicityIds != null
    ) {
      profileForm.append("ethnicityIds", this.selectedEthnicityIds.join(","));
    }
    // profileForm.append('age', this.selectedAge.toString());

    if (this.isRelocateY === true) {
      profileForm.append("isWillingToRelocate", "true");
    } else if (this.isRelocateN === true) {
      profileForm.append("isWillingToRelocate", "false");
    } else {
      profileForm.append("isWillingToRelocate", "");
    }

    profileForm.append("file", this.fileToUpload);
    return profileForm;
  }
  logout() {
    this.employeeProfileClose.emit(false);
    this.uiSyncService.onLoggedOut.next(true);
    this.isSelectAboutUs = false;
    this.isSelectLogin = false;
    // this.isSelectSignUp = false;
    this.authService.logout();
    this.authService.currentUser = null;
    this.loginUser = null;
    this.loggedOut.emit(true);
    localStorage.removeItem("searchKey");
    localStorage.removeItem("career");
    localStorage.removeItem("people");
    localStorage.removeItem("facetedSelection");
    localStorage.removeItem("selectedRoleId");
    localStorage.removeItem("selectedClientId");
    localStorage.removeItem("selectedClientName");
    localStorage.removeItem("careerGroupName");
    localStorage.removeItem("hrNavigateRoute");
    localStorage.removeItem("employeeNavigateRoute");
    this.uiSyncService.selectedRoleId = 0;
    this.uiSyncService.selectedClientId = 0;
    this.uiSyncService.selectedClientName = "";
    this.uiSyncService.onClientSelected.next(null);
    this.uiSyncService.onSelectedClientName.next(null);
    this.uiSyncService.onClientSelectionForAQBReporting.next(null);
    // this.uiSyncService.onSelectedClientName.complete();
    // this.uiSyncService.onClientSelected.complete();
    this.uiSyncService.userAllRoles.next([]);
    this.uiSyncService.isDisableClientDropdown.next(null);
    this.uiSyncService.clientLogoUrl.next(
      "assets/images/catalyzr-logo-white.png"
    );
    // this.router.navigate(['/login']);
    // this.dialogRef.close(true);
    this.route.navigate(["/auth/login"]);
    console.log("Logged Out!");
    this.uiSyncService.employeeImageUrl.next(null);

    //Disconnect signalR
    this.signalRService.disconnect();

    this.alertifyService.message("Logout Successfully!");
  }

  onChangeProfile() {
    const empProfile = this.prepareSave();
    this.employeeService.updateEmpProfile(empProfile).subscribe(
      async (response) => {
        await this.authService.getEmployeeImageUrl();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  WillRelocate(isClickedYes, isChecked) {
    debugger;
    if (isClickedYes && isChecked) {
      this.isRelocateY = true;
      this.isRelocateN = false;
    } else if (isClickedYes && !isChecked) {
      this.isRelocateY = false;
    } else if (!isClickedYes && isChecked) {
      this.isRelocateY = false;
      this.isRelocateN = true;
    } else {
      this.isRelocateN = false;
    }

    this.onChangeProfile();
  }

  closeEmployee() {
    this.employeeProfileClose.emit(false);
  }
  OnChange() {
    debugger;
    alert("Hi");
  }
  // all ethnicities
  getEthnicities() {
    this.employeeService.getEthnicities().subscribe(
      (response: Ethnicity[]) => {
        this.ethnicities = response;
        console.log(this.ethnicities);
        console.log("all");
        if (
          this.ethnicities !== null &&
          this.ethnicities !== undefined &&
          this.ethnicities.length > 0
        ) {
          this.ethnicities.forEach((ethnicity) => {
            this.data.push({
              id: ethnicity.id,
              name: ethnicity.name,
            });
          });
          if (
            this.selectedEthnicityIds !== null &&
            this.selectedEthnicityIds !== undefined &&
            this.selectedEthnicityIds.length > 0
          ) {
            this.selectedEthnicityIds.forEach((id) => {
              var existingEthnicity = this.ethnicities.find((c) => c.id === id);
              if (
                existingEthnicity !== null &&
                existingEthnicity !== undefined
              ) {
                this.selectedRaces.push({
                  id: existingEthnicity.id,
                  name: existingEthnicity.name,
                });
              }
            });
          }
        }
      },
      (error) => {
        this.alertifyService.error("There are no ethnicity found.");
      }
    );
  }

  // on click current career
  onClickCurrentCareer() {
    // this.dialogRef.close();
    // localStorage.setItem('FromEmployeeProfile', 'true');
    // this.route.navigate(['/employee/career-details', this.catalyzrPersonId, this.clientId, this.employeeInfo.clientJobCode, this.employeeInfo.currentCareerOSOCCode]);
  }

  onLoadProfileIamge() {
    this.loadingProfileIamge = false;
  }
  onLoadCareerImage() {
    this.loadingCareerImage = false;
  }

  afterApplyCrop($event) {
    this.fileToUpload = $event;
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
      this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(this.imageUrl);
    };
    reader.readAsDataURL(this.fileToUpload);
    this.onChangeProfile();
  }

  closeShowPopup($event) {
    this.showPopup = $event;
  }
  onToggleChange(event: MatSlideToggleChange) {
    this.otpLoginEnabled = event.checked;
    console.log("OTP Login Enabled:", this.otpLoginEnabled);
    this.disableToggole = true;
    this.authService
      .updateUserLoginOtpStatus(this.currentUser.id, this.otpLoginEnabled)
      .subscribe({
        next: (res) => {
          this.disableToggole = false;
          this.currentUser.isOTPEnabled = event.checked;
          this.authService.currentUser.isOTPEnabled = event.checked;
          localStorage.removeItem("user");
          localStorage.setItem("user", JSON.stringify(this.currentUser));
          console.log("OTP login status updated successfully:", res);
        },
        error: (err) => {
          this.disableToggole = false;
          this.otpLoginEnabled = !event.checked;
          console.error("Failed to update OTP login status:", err);
        },
      });
  }
}
