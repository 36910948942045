export interface Pagination {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
}

export class PaginatedResult<T> {
    result: T;
    pagination: Pagination;
}

export class PaginationNextPrevious {
    public pagination: Pagination;
    public isDisableNextButton: boolean = true;
    public isDisablePreviousButton: boolean = true;
    public nextPreviousFunc() {
        if (this.pagination && this.pagination.totalItems > (this.pagination.currentPage * this.pagination.itemsPerPage)) {
            this.isDisableNextButton = false;
        } else {
            this.isDisableNextButton = true;
        }
        if (this.pagination.currentPage === 1) {
            this.isDisablePreviousButton = true;
        }
        if (this.pagination.currentPage > 1) {
            this.isDisablePreviousButton = false;
        }
    }
}