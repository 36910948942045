import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, merge, Subject, timer } from 'rxjs';
import { switchMap, startWith, tap } from 'rxjs/operators';
import { AppAuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AutoLogoutService {
  private idleTimeout = 20 * 60 * 1000; // 20 minutes in milliseconds
  private userActivity = new Subject<void>();

  constructor(private router: Router, public authService: AppAuthService) {
    this.startTracking();
  }

  private startTracking(): void {
    // console.log('Starting user activity tracking...');
    const activityEvents = merge(
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'click'),
      fromEvent(window, 'keydown')
    );

    activityEvents
      .pipe(
        // Start with an initial trigger so the timer starts immediately
        startWith(null),
        tap(() => {
          // console.log('User activity detected, resetting the timer...');
          this.userActivity.next();
        }),
        switchMap(() => {
          // console.log(`No activity, starting the ${this.idleTimeout / 1000 / 60} minute countdown to logout...`);
          return timer(this.idleTimeout);
        })
      )
      .subscribe(() => {
        // console.log(`No activity detected for ${this.idleTimeout / 1000 / 60} minutes, logging out...`);
        this.logout();
      });
  }

  private logout(): void {
    console.log('Performing logout...');
    this.authService.logout();
    sessionStorage.clear();
    this.router.navigate(['/auth/login']);
    // console.log('User has been logged out and redirected to the login page.');
  }
}
